import axios from "axios";

export default {
    namespaced: true,
    state: {
        contractors: []
    },
    getters: {},
    mutations: {
        SET_CONTRACTORS(state, authState) {
            state.contractors = authState
        },
    },

    actions: {
        async loadContractors({commit}) {
            try {
                let res = await axios.get('/contractors/get_list_contractors');
                if (res.status === 200) {
                    commit('SET_CONTRACTORS', res.data);
                    return true;
                }
            } catch (e) {
                console.log(e);
            }
            return false
        },

        async createContractor({commit, dispatch}, fields) {
            try {
                let res = await axios.post('/contractors/create_contractor', fields);
                if (res.status === 200) {
                    dispatch('loadContractors');
                    commit('SET_LAST_CREATE_CONTRACTOR', res.data);
                    return true;
                }
            } catch (e) {
                console.log(e);
            }
            return false
        },

        async delContractor({commit, dispatch}, {contractorId}) {
            try {
                let res = await axios.post('/contractors/del_contractor', {
                    contractorId
                });
                if (res.status === 200) {
                    dispatch('loadContractors');
                    return true;
                }
            } catch (e) {
                console.log(e);
            }
            return false
        },

        async updateContractor({commit, dispatch}, {contractorId, values}) {
            try {
                let res = await axios.post('/contractors/update_contractor', {
                    contractorId, values
                });
                if (res.status === 200) {
                    return true;
                }
            } catch (e) {
                console.log(e);
            }
            return false
        },
    },
}
