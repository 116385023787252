import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/',
            name: 'products',
            component: () => import('@/pages/Products.vue'),
            meta: {
                pageTitle: 'products',
                breadcrumb: [
                    {
                        text: 'products',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/shipments',
            name: 'shipments',
            component: () => import('@/pages/Shipments.vue'),
            meta: {
                pageTitle: 'shipments',
                breadcrumb: [
                    {
                        text: 'shipments',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/creating-shipment',
            name: 'creatingShipment',
            component: () => import('@/pages/CreatingShipment.vue'),
            meta: {
                pageTitle: 'creating-shipment',
                breadcrumb: [
                    {
                        text: 'creating-shipment',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/creating-delivery',
            name: 'creatingDelivery',
            component: () => import('@/pages/CreatingDelivery.vue'),
            meta: {
                pageTitle: 'creating-delivery',
                breadcrumb: [
                    {
                        text: 'creating-delivery',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/delivery',
            name: 'delivery',
            component: () => import('@/pages/Delivery.vue'),
            meta: {
                pageTitle: 'delivery',
                breadcrumb: [
                    {
                        text: 'delivery',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/calculator',
            name: 'calculator',
            component: () => import('@/pages/Calculator.vue'),
            meta: {
                pageTitle: 'calculator',
                breadcrumb: [
                    {
                        text: 'calculator',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/setting',
            name: 'setting',
            component: () => import('@/pages/Setting.vue'),
            meta: {
                pageTitle: 'setting',
                breadcrumb: [
                    {
                        text: 'setting',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/wiki',
            name: 'wiki',
            component: () => import('@/pages/Wiki.vue'),
            meta: {
                pageTitle: 'wiki',
                breadcrumb: [
                    {
                        text: 'wiki',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/register',
            name: 'register',
            component: () => import('@/views/Register.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: () => import('@/views/Privacy.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/policy',
            name: 'policy',
            component: () => import('@/views/Policy.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/offer',
            name: 'offer',
            component: () => import('@/views/Offer.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/procurement',
            name: 'procurement',
            component: () => import('@/pages/Procurement.vue'),
            meta: {
                pageTitle: 'procurement',
                breadcrumb: [
                    {
                        text: 'procurement',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/orders',
            name: 'orders',
            component: () => import('@/pages/Orders.vue'),
            meta: {
                pageTitle: 'orders',
                breadcrumb: [
                    {
                        text: 'orders',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/confirm',
            name: 'confirm',
            component: () => import('@/pages/Confirm.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/procurement/:id',
            name: 'procurement-item',
            component:  () => import('@/components/procurement/PurchaseItem'),
            meta: {
                pageTitle: 'Закупка',
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/unit-report',
            name: 'unit-report',
            component: () => import('@/pages/UnitsReport.vue'),
            meta: {
                pageTitle: 'unit-аналитика',
                breadcrumb: [
                    {
                        text: 'unit-аналитика',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/charts',
            name: 'charts',
            component: () => import('@/pages/Charts.vue'),
            meta: {
                pageTitle: 'графики',
                breadcrumb: [
                    {
                        text: 'unit-аналитика',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.afterEach(() => {

})

export default router
