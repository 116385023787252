<template>
  <span>{{ formattedCost }}</span>
</template>

<script>
export default {
  name: "MoneyFormat",
  data() {
    return {}
  },
  props: {
    cost: {
      type: [Number, String],
      default() {
        return 0
      }
    },
    roundMethod: {
      type: String,
      default() {
        return ''
      }
    }
  },
  computed: {
    formattedCost() {
      const formattedValue = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 0
      }).format(Math[this.roundMethod] ? Math[this.roundMethod](this.cost) : this.cost);

      return formattedValue.replace('₽', '');
    }
  }
}
</script>

<style scoped>


</style>