let self;
const monthList = {
    'jan': 1,
    'feb': 2,
    'mar': 3,
    'apr': 4,
    'may': 5,
    'jun': 6,
    'jul': 7,
    'aug': 8,
    'sep': 9,
    'oct': 10,
    'nov': 11,
    'dec': 12,
};
const monthListNum = {
    1: 'jan',
    2: 'feb',
    3: 'mar',
    4: 'apr',
    5: 'may',
    6: 'jun',
    7: 'jul',
    8: 'aug',
    9: 'sep',
    10: 'oct',
    11: 'nov',
    12: 'dec',
};

class TransactionTableRowsBuilder {
    balance = {
        total: {
            current: {
                totalMonth: 0,
                month: 0,
                monthName: 0,
                year: 0,
                value: 0,
            },
            prev: {
                totalMonth: 0,
                month: 0,
                monthName: 0,
                year: 0,
                value: 0,
            },
        }
    };

    rows = {
        StartMonthRow: {},
        EndMonthRow: {},
        totalExpenses: {},
        totalTurnover: {},

        StartMonthDetailRows: {},
        EndMonthDetailRows: {},
    }

    rowsActType = {
        OperationsRow: {},
        OperationsExpenditureRow: {},
        OperationsReceiptsRow: {},

        InvestmentsRow: {},
        InvestmentsExpenditureRow: {},
        InvestmentsReceiptsRow: {},

        FinanceRow: {},
        FinanceExpenditureRow: {},
        FinanceReceiptsRow: {},
    }

    rowsActTypeParams = {
        OperationsRow: {
            title: 'операционная деятельность',
            levelGroup: 'top',
            name: 'operations',
        },

        OperationsExpenditureRow: {
            title: 'Списание',
            levelGroup: 'middle',
            name: 'operationsExpenditure',
            parent: 'operations',
        },

        OperationsReceiptsRow: {
            title: 'Поступление',
            levelGroup: 'middle',
            name: 'operationsReceipts',
            parent: 'operations',
        },

        InvestmentsRow: {
            title: 'инвестиционная деятельность',
            levelGroup: 'top',
            name: 'investments',
        },

        InvestmentsExpenditureRow: {
            title: 'Списание',
            levelGroup: 'middle',
            name: 'investmentsExpenditure',
            parent: 'investments',
        },

        InvestmentsReceiptsRow: {
            title: 'Поступление',
            levelGroup: 'middle',
            name: 'investmentsReceipts',
            parent: 'investments',
        },

        FinanceRow: {
            title: 'финансовая деятельность',
            levelGroup: 'top',
            name: 'finance',
        },

        FinanceExpenditureRow: {
            title: 'Списание',
            levelGroup: 'middle',
            name: 'financeExpenditure',
            parent: 'finance',
        },

        FinanceReceiptsRow: {
            title: 'Поступление',
            levelGroup: 'middle',
            name: 'financeReceipts',
            parent: 'finance',
        },
    }

    rowsActTypeDetail = {
        InvestmentsExpenditureDetailRows: {},
        InvestmentsReceiptsDetailRows: {},
        OperationsExpenditureDetailRows: {},
        OperationsReceiptsDetailRows: {},
        FinanceExpenditureDetailRows: {},
        FinanceReceiptsDetailRows: {},
    }

    rowsActTypeDetailParams = {
        InvestmentsExpenditureDetailRows: {
            parent: 'investmentsReceipts',
        },
        InvestmentsReceiptsDetailRows: {
            parent: 'investmentsExpenditure',
        },
        OperationsExpenditureDetailRows: {
            parent: 'operationsExpenditure',
        },
        OperationsReceiptsDetailRows: {
            parent: 'operationsReceipts',
        },
        FinanceExpenditureDetailRows: {
            parent: 'financeExpenditure',
        },
        FinanceReceiptsDetailRows: {
            parent: 'financeReceipts',
        },
    }

    handlers = {
        "Инвестиционная_поступление"({tr, year}) {
            if (!self.rowsActTypeDetail.InvestmentsReceiptsDetailRows[tr.expenseItem]) self.rowsActTypeDetail.InvestmentsReceiptsDetailRows[tr.expenseItem] = {};
            if (!self.rowsActTypeDetail.InvestmentsReceiptsDetailRows[tr.expenseItem][year]) {
                self.rowsActTypeDetail.InvestmentsReceiptsDetailRows[tr.expenseItem][year] = self.getTemp({
                    year,
                    title: tr.expenseItem,
                    levelGroup: 'bottom',
                    name: tr.expenseItem,
                    parent: 'investmentsReceipts',
                })
            }
        },

        "Инвестиционная_списание"({tr, year}) {
            if (!self.rowsActTypeDetail.InvestmentsExpenditureDetailRows[tr.expenseItem]) self.rowsActTypeDetail.InvestmentsExpenditureDetailRows[tr.expenseItem] = {};
            if (!self.rowsActTypeDetail.InvestmentsExpenditureDetailRows[tr.expenseItem][year]) {
                self.rowsActTypeDetail.InvestmentsExpenditureDetailRows[tr.expenseItem][year] = self.getTemp({
                    year,
                    title: tr.expenseItem,
                    levelGroup: 'bottom',
                    name: tr.expenseItem,
                    parent: 'investmentsExpenditure',
                })
            }
        },

        "Операционная_поступление"({tr, year}) {
            if (!self.rowsActTypeDetail.OperationsReceiptsDetailRows[tr.expenseItem]) self.rowsActTypeDetail.OperationsReceiptsDetailRows[tr.expenseItem] = {};
            if (!self.rowsActTypeDetail.OperationsReceiptsDetailRows[tr.expenseItem][year]) {
                self.rowsActTypeDetail.OperationsReceiptsDetailRows[tr.expenseItem][year] = self.getTemp({
                    year,
                    title: tr.expenseItem,
                    levelGroup: 'bottom',
                    name: tr.expenseItem,
                    parent: 'operationsReceipts',
                })
            }
        },

        "Операционная_списание"({tr, year}) {
            if (!self.rowsActTypeDetail.OperationsExpenditureDetailRows[tr.expenseItem]) self.rowsActTypeDetail.OperationsExpenditureDetailRows[tr.expenseItem] = {};
            if (!self.rowsActTypeDetail.OperationsExpenditureDetailRows[tr.expenseItem][year]) {
                self.rowsActTypeDetail.OperationsExpenditureDetailRows[tr.expenseItem][year] = self.getTemp({
                    year,
                    title: tr.expenseItem,
                    levelGroup: 'bottom',
                    name: tr.expenseItem,
                    parent: 'operationsExpenditure',
                })
            }
        },

        "Финансовая_поступление"({tr, year}) {
            if (!self.rowsActTypeDetail.FinanceReceiptsDetailRows[tr.expenseItem]) self.rowsActTypeDetail.FinanceReceiptsDetailRows[tr.expenseItem] = {};
            if (!self.rowsActTypeDetail.FinanceReceiptsDetailRows[tr.expenseItem][year]) {
                self.rowsActTypeDetail.FinanceReceiptsDetailRows[tr.expenseItem][year] = self.getTemp({
                    year,
                    title: tr.expenseItem,
                    levelGroup: 'bottom',
                    name: tr.expenseItem,
                    parent: 'financeReceipts',
                })
            }
        },

        "Финансовая_списание"({tr, year}) {
            if (!self.rowsActTypeDetail.FinanceExpenditureDetailRows[tr.expenseItem]) self.rowsActTypeDetail.FinanceExpenditureDetailRows[tr.expenseItem] = {};
            if (!self.rowsActTypeDetail.FinanceExpenditureDetailRows[tr.expenseItem][year]) {
                self.rowsActTypeDetail.FinanceExpenditureDetailRows[tr.expenseItem][year] = self.getTemp({
                    year,
                    title: tr.expenseItem,
                    levelGroup: 'bottom',
                    name: tr.expenseItem,
                    parent: 'financeExpenditure',
                })
            }
        },
    }

    fillRowsActions = {
        "Инвестиционная"({totalMonthTemp, tr, year, month}) {
            if (tr.year * 12 + tr.month === totalMonthTemp) {
                tr.typeAction === 'поступление' ? self.rowsActType.InvestmentsReceiptsRow[year][month] += +tr.summa :
                    self.rowsActType.InvestmentsExpenditureRow[year][month] += +tr.summa;
                self.rowsActType.InvestmentsRow[year][month] = self.rowsActType.InvestmentsReceiptsRow[year][month] - self.rowsActType.InvestmentsExpenditureRow[year][month];

                if (tr.typeAction === 'поступление') {
                    let yearR = self.rowsActTypeDetail.InvestmentsReceiptsDetailRows[tr.expenseItem][year];
                    yearR[month] += +tr.summa;
                    yearR.totalRowSumma += +tr.summa;
                } else {
                    let yearE = self.rowsActTypeDetail.InvestmentsExpenditureDetailRows[tr.expenseItem][year];
                    yearE[month] += +tr.summa;
                    yearE.totalRowSumma += +tr.summa;
                }
            }
        },

        "Операционная"({totalMonthTemp, tr, year, month}) {
            if (tr.year * 12 + tr.month === totalMonthTemp) {
                tr.typeAction === 'поступление' ? self.rowsActType.OperationsReceiptsRow[year][month] += +tr.summa :
                    self.rowsActType.OperationsExpenditureRow[year][month] += +tr.summa;
                self.rowsActType.OperationsRow[year][month] = self.rowsActType.OperationsReceiptsRow[year][month] - self.rowsActType.OperationsExpenditureRow[year][month];

                if (tr.typeAction === 'поступление') {
                    let yearR = self.rowsActTypeDetail.OperationsReceiptsDetailRows[tr.expenseItem][year];
                    yearR[month] += +tr.summa;
                    yearR.totalRowSumma += +tr.summa;
                } else {
                    let yearE = self.rowsActTypeDetail.OperationsExpenditureDetailRows[tr.expenseItem][year];
                    yearE[month] += +tr.summa;
                    yearE.totalRowSumma += +tr.summa;
                }
            }
        },

        "Финансовая"({totalMonthTemp, tr, year, month}) {
            if (tr.year * 12 + tr.month === totalMonthTemp) {
                tr.typeAction === 'поступление' ? self.rowsActType.FinanceReceiptsRow[year][month] += +tr.summa :
                    self.rowsActType.FinanceExpenditureRow[year][month] += +tr.summa;
                self.rowsActType.FinanceRow[year][month] = self.rowsActType.FinanceReceiptsRow[year][month] - self.rowsActType.FinanceExpenditureRow[year][month];

                if (tr.typeAction === 'поступление') {
                    let yearR = self.rowsActTypeDetail.FinanceReceiptsDetailRows[tr.expenseItem][year];
                    yearR[month] += +tr.summa;
                    yearR.totalRowSumma += +tr.summa;
                } else {
                    let yearE = self.rowsActTypeDetail.FinanceExpenditureDetailRows[tr.expenseItem][year];
                    yearE[month] += +tr.summa;
                    yearE.totalRowSumma += +tr.summa;
                }
            }
        },
    }

    years = {}

    constructor() {
        self = this
    }

    setYearsRowsActTypeDetail({tr, year}) {
        for (const rows in self.rowsActTypeDetail) {
            for (const expenseName in self.rowsActTypeDetail[rows]) {
                if (!self.rowsActTypeDetail[rows][expenseName][year]) {
                    self.rowsActTypeDetail[rows][expenseName][year] = self.getTemp({
                        year,
                        title: expenseName,
                        levelGroup: 'bottom',
                        name: expenseName,
                        parent: self.rowsActTypeDetailParams[rows].parent,
                    })
                }
            }
        }
    }

    updateTotalBalance(tr) {
        const {month, year, summa} = tr;
        if (+self.balance.total.current.month !== +month || +self.balance.total.current.year !== +year) {
            self.balance.total.prev = {
                totalMonth: self.balance.total.current.totalMonth,
                month: self.balance.total.current.month,
                monthName: self.balance.total.current.monthName,
                year: self.balance.total.current.year,
                value: self.balance.total.current.value,
            }

            self.balance.total.current = {
                totalMonth: month + year * 12,
                month: month,
                monthName: monthListNum[month],
                year: year,
                value: tr.typeAction === "поступление" ? self.balance.total.current.value + +summa : self.balance.total.current.value - +summa,
            }
        } else {
            self.balance.total.current.value = tr.typeAction === "поступление" ? self.balance.total.current.value + +summa : self.balance.total.current.value - +summa
        }
    }

    updateFinAccountsBalance(tr) {
        const {month, year, summa} = tr;
        if (!self.balance[tr.finAccount]) {
            self.balance[tr.finAccount] = {
                current: {
                    totalMonth: month + year * 12,
                    month: month,
                    monthName: monthListNum[month],
                    year: year,
                    value: tr.typeAction === "поступление" ? +summa : -+summa,
                },
                prev: {
                    totalMonth: 0,
                    month: 0,
                    monthName: 0,
                    year: 0,
                    value: 0,
                },
            }
        } else {
            if (+self.balance[tr.finAccount].current.month !== +month || +self.balance[tr.finAccount].current.year !== +year) {
                self.balance[tr.finAccount].prev = {
                    totalMonth: self.balance[tr.finAccount].current.totalMonth,
                    month: self.balance[tr.finAccount].current.month,
                    monthName: self.balance[tr.finAccount].current.monthName,
                    year: self.balance[tr.finAccount].current.year,
                    value: self.balance[tr.finAccount].current.value,
                }

                self.balance[tr.finAccount].current = {
                    totalMonth: month + year * 12,
                    month: month,
                    monthName: monthListNum[month],
                    year: year,
                    value: tr.typeAction === "поступление" ? self.balance[tr.finAccount].current.value + +summa : self.balance[tr.finAccount].current.value - +summa,
                }

            } else {
                self.balance[tr.finAccount].current.value = tr.typeAction === "поступление" ? self.balance[tr.finAccount].current.value + +summa : self.balance[tr.finAccount].current.value - +summa
            }
        }
    }

    setStartTotalBalanceValue(trs) {
        let firstTr = trs[0];
        self.balance.total.current = {
            totalMonth: firstTr.month + firstTr.year * 12,
            month: firstTr.month,
            monthName: monthListNum[firstTr.month],
            year: firstTr.year,
            value: firstTr.typeAction === "поступление" ? +firstTr.summa : -+firstTr.summa,
        }

        self.balance.total.prev = {
            totalMonth: firstTr.month + firstTr.year * 12,
            month: firstTr.month,
            monthName: monthListNum[firstTr.month],
            year: firstTr.year,
            value: firstTr.typeAction === "поступление" ? +firstTr.summa : -+firstTr.summa,
        }
    }

    getRows() {
        let topRows = {}
        let startMonthDetailRows = {}
        let EndMonthDetailRows = {}
        let OperationsBalance = {}
        let InvestmentsBalance = {}
        let FinanceBalance = {}
        let rowsActTypeDetail = {}

        for (const year in self.years) {
            topRows[year] = [
                self.rows.StartMonthRow[year],
                self.rowsActType.OperationsRow[year],
                self.rowsActType.FinanceRow[year],
                self.rowsActType.InvestmentsRow[year],
                self.rows.totalExpenses[year],
                self.rows.totalTurnover[year],
                self.rows.EndMonthRow[year],
            ]

            OperationsBalance[year] = [
                self.rowsActType.OperationsReceiptsRow[year],
                self.rowsActType.OperationsExpenditureRow[year],
            ]

            InvestmentsBalance[year] = [
                self.rowsActType.InvestmentsReceiptsRow[year],
                self.rowsActType.InvestmentsExpenditureRow[year],
            ]

            FinanceBalance[year] = [
                self.rowsActType.FinanceReceiptsRow[year],
                self.rowsActType.FinanceExpenditureRow[year],
            ]

            for (const rows in self.rowsActTypeDetail) {
                if (!rowsActTypeDetail[rows]) rowsActTypeDetail[rows] = {}
                rowsActTypeDetail[rows][year] = []
                for (const expenseName in self.rowsActTypeDetail[rows]) {
                    rowsActTypeDetail[rows][year].push(self.rowsActTypeDetail[rows][expenseName][year]);
                }
                rowsActTypeDetail[rows][year].sort((itm, itm1)=> itm1.totalRowSumma - itm.totalRowSumma);
            }


            startMonthDetailRows[year] = [];
            EndMonthDetailRows[year] = [];

            for (const finAccName in self.rows.StartMonthDetailRows) {
                startMonthDetailRows[year].push(self.rows.StartMonthDetailRows[finAccName][year]);
                EndMonthDetailRows[year].push(self.rows.EndMonthDetailRows[finAccName][year]);
            }
        }

        let rowsList = {
            topRows,
            startMonthDetailRows,
            EndMonthDetailRows,
            OperationsBalance,
            InvestmentsBalance,
            FinanceBalance
        };

        Object.assign(rowsList, rowsActTypeDetail);
        return rowsList;
    }

    createRows(transactions) {
        for (const tr of transactions) {
            self.years[tr.year] = 1;
            self.updateFinAccountsBalance(tr);
            self.updateTotalBalance(tr);
            if (!self.rows.StartMonthDetailRows[tr.finAccount]) {
                self.rows.StartMonthDetailRows[tr.finAccount] = {}
                self.rows.EndMonthDetailRows[tr.finAccount] = {}
            }

            if (!self.rows.StartMonthRow[tr.year]) {
                self.rows.StartMonthRow[tr.year] = self.getTemp({
                    year: tr.year,
                    title: 'денег на начало месяца',
                    levelGroup: 'top',
                    name: 'startMonthRow'
                })
                self.rows.EndMonthRow[tr.year] = self.getTemp({
                    year: tr.year,
                    title: 'денег на конец месяца',
                    levelGroup: 'top',
                    name: 'endMonthRow'
                })
                self.rows.totalExpenses[tr.year] = self.getTemp({
                    year: tr.year,
                    title: 'итого расходы',
                    levelGroup: 'top',
                    name: 'totalExpenses'
                })
                delete self.rows.totalExpenses[tr.year].showDetails
                self.rows.totalTurnover[tr.year] = self.getTemp({
                    year: tr.year,
                    title: 'итого оборот денег за месяц',
                    levelGroup: 'top',
                    name: 'totalTurnover'
                })
                delete self.rows.totalTurnover[tr.year].showDetails
            }

            for (const year in self.years) {
                for (const finAccountName in self.rows.StartMonthDetailRows) {
                    if (!self.rows.StartMonthDetailRows[finAccountName][year]) {
                        self.rows.StartMonthDetailRows[finAccountName][year] = self.getTemp({
                            year: year,
                            title: finAccountName,
                            levelGroup: 'bottom',
                            name: 'startMonthDetailRows',
                            parent: 'startMonthRow'
                        })
                        self.rows.EndMonthDetailRows[finAccountName][year] = self.getTemp({
                            year: year,
                            title: finAccountName,
                            levelGroup: 'bottom',
                            name: 'endMonthDetailRows',
                            parent: 'endMonthRow'
                        })
                    }
                }
                for (const rowName in self.rowsActType) {
                    if (!self.rowsActType[rowName][year]) {
                        self.rowsActType[rowName][year] = self.getTemp(Object.assign({year: year}, self.rowsActTypeParams[rowName]))
                    }
                }

                self.handlers[`${tr.type_activity}_${tr.typeAction}`]({tr, year});
                self.setYearsRowsActTypeDetail({tr, year});
            }

            let {totalMonth, value} = self.balance.total.current
            let totalMonthPrev = self.balance.total.prev.totalMonth
            let prevValue = self.balance.total.prev.value

            for (const year in self.rows.StartMonthRow) {
                for (const month in self.rows.StartMonthRow[year]) {
                    let totalMonthTemp = monthList[month] && year * 12 + monthList[month];
                    if (totalMonthTemp <= totalMonth) {
                        if (totalMonthTemp === totalMonth) {
                            self.rows.EndMonthRow[year][month] = value
                            self.rows.StartMonthRow[year][month] = prevValue
                        } else if (totalMonthTemp > totalMonthPrev) {
                            self.rows.EndMonthRow[year][month] = prevValue
                            self.rows.StartMonthRow[year][month] = prevValue
                        }
                        self.setDetailsRows({totalMonthTemp, tr, year, month})
                        self.setRowsActType({totalMonthTemp, tr, year, month})
                        self.setTotalExpenses({totalMonthTemp, tr, year, month})
                    }
                }
            }
        }

        return self.getRows()
    }

    getTemp({year, title, levelGroup, name, parent,}) {
        return {
            year,
            title,
            levelGroup,
            name,
            parent,
            'showDetails': false,
            'jan': 0,
            'feb': 0,
            'mar': 0,
            'apr': 0,
            'may': 0,
            'jun': 0,
            'jul': 0,
            'aug': 0,
            'sep': 0,
            'oct': 0,
            'nov': 0,
            'dec': 0,
            'totalRowSumma': 0
        }
    }

    setDetailsRows({totalMonthTemp, tr, year, month}) {
        for (const finAccount in self.rows.StartMonthDetailRows) {
            let totalMonth = self.balance[finAccount].current.totalMonth;
            let totalMonthPrev = self.balance[finAccount].prev.totalMonth;
            if (finAccount === tr.finAccount) {
                if (totalMonthTemp === totalMonth) {
                    self.rows.EndMonthDetailRows[finAccount][year][month] = self.balance[finAccount].current.value
                    self.rows.StartMonthDetailRows[finAccount][year][month] = self.balance[finAccount].prev.value
                } else if (totalMonthPrev && totalMonthPrev > totalMonth) {
                    self.rows.EndMonthDetailRows[finAccount][year][month] = self.balance[finAccount].prev.value
                    self.rows.StartMonthDetailRows[finAccount][year][month] = self.balance[finAccount].prev.value
                }
            } else if (+totalMonthTemp === +totalMonth) {
                self.rows.EndMonthDetailRows[finAccount][year][month] = self.balance[finAccount].current.value
            } else if (totalMonthTemp > totalMonth) {
                self.rows.EndMonthDetailRows[finAccount][year][month] = self.balance[finAccount].current.value
                self.rows.StartMonthDetailRows[finAccount][year][month] = self.balance[finAccount].current.value
            }
        }

    }

    setRowsActType({totalMonthTemp, tr, year, month}) {
        self.fillRowsActions[tr.type_activity]({totalMonthTemp, tr, year, month});
    }

    setTotalExpenses({totalMonthTemp, tr, year, month}) {
        if (tr.year * 12 + tr.month === totalMonthTemp) {
            self.rows.totalExpenses[year][month] = self.rowsActType.OperationsExpenditureRow[year][month] + self.rowsActType.InvestmentsExpenditureRow[year][month];
            self.rows.totalTurnover[year][month] =
                self.rowsActType.OperationsRow[year][month] +
                self.rowsActType.InvestmentsRow[year][month] +
                self.rowsActType.FinanceRow[year][month];
        }
    }
}

module.exports = TransactionTableRowsBuilder;