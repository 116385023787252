import axios from "axios";

export default {
    namespaced: true,

    state: {
        purchases: [],
        contractors: [],
        typeContractors: [],
        warehouses: [],
        statuses: [],
        filterData: null
    },

    getters: {
        getListPurchases(state, getters) {
            return state.purchases
        }
    },

    mutations: {
        SET_PURCHASES(state, data) {
            state.purchases = data
        },

        SET_WAREHOUSES(state, data) {
            state.warehouses = data
        },

        SET_CONTRACTORS(state, data) {
            state.contractors = data
        },

        SET_STATUSES(state, data) {
            state.statuses = data
        },

        SET_TYPE_CONTRACTORS(state, data) {
            state.typeContractors = data
        },

        SET_STATUS(state, {id, status}) {
            let purchase = state.purchases.find(item => +item.id === +id);
            if (purchase) purchase.status = status;
        }
    },

    actions: {
        async loadListPurchase({commit, dispatch}) {
            try {
                let purchase = await axios.get('/purchase/get_list');
                if (purchase.status === 200) {
                    purchase = purchase.data
                    commit('SET_PURCHASES', purchase);
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },
        async createPurchase({commit, dispatch}, data) {
            try {
                let purchase = await axios.post('/statistics/purchase/create', data);
                if (purchase.status === 200) {
                    purchase = purchase.data
                    return purchase
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },
        async loadCatalogs({commit, dispatch}) {
            try {
                let catalogs = await axios.get('/purchase/get_catalogs');
                if (catalogs.status === 200) {
                    catalogs = catalogs.data
                    commit('SET_WAREHOUSES', catalogs.warehouses);
                    commit('SET_CONTRACTORS', catalogs.contractors);
                    commit('SET_TYPE_CONTRACTORS', catalogs.typeContractors);
                    commit('SET_STATUSES', catalogs.statuses);
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },
        async addWarehouse({commit, dispatch}, {address, name}) {
            try {
                let catalogs = await axios.post('/purchase/create_warehouse', {
                    address,
                    name
                });
                if (catalogs.status === 200) {
                    dispatch('loadCatalogs');
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },
        async delPurchase({commit, dispatch}, id) {
            try {
                let catalogs = await axios.post('/purchase/del', {
                    procurementId: id
                });
                if (catalogs.status === 200) {
                    dispatch('loadListPurchase');
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },

        async setStatus({commit, dispatch}, {status, id}) {
            try {
                let success = await axios.post('/purchase/set_status', {
                    procurementId: id,
                    status
                });
                if (success.status === 200) {
                    commit('SET_STATUS', {id, status});
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },

        async addContractor({commit, dispatch}, {name, inn, kpp, legalAddress, type, address}) {
            try {
                let catalogs = await axios.post('/purchase/create_contractor', {
                    name,
                    inn,
                    kpp,
                    legalAddress,
                    type,
                    address
                });
                if (catalogs.status === 200) {
                    dispatch('loadCatalogs');
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        }
    },
}
