const resolvePrices = {
    first_pack(service, product) {
        service.type
    }
}

const testProdToSupply = [
    {
        totalAmount: 0,
        totalPrice: 0,
        services: [],
        servVariant: null,
        amountBySize: [
            {
                size: 'S',
                amount: 0,
                stock: 10
            }
        ],
    },
    {
        id: 0,
        totalAmount: 0,
        totalPrice: 0,
        product: {},
        servVariant: null,
        services: [],
        stock: 200,
    },]

export default {
    namespaced: true,
    state: {
        products: [],
    },
    getters: {},
    mutations: {
        SET_AMOUNT_BY_SIZE(state, {idProd, size, amount}) {
            state.products.some(prod => {
                if (prod.id === idProd) {
                    if (Array.isArray(prod.amountBySize)) {
                        prod.amountBySize.some(abs => {
                            if (abs.size === size) {
                                prod.totalAmount -= abs.amount;
                                //abs.amount = amount > abs.stock ? abs.stock : amount;
                                //prod.totalAmount += abs.amount;
                                abs.amount = amount;
                                prod.totalAmount += amount;
                                let summa = prod.services.reduce((acc, serv) => {
                                    if (serv.price) return acc + serv.price
                                    else if (typeof resolvePrices[serv.key] === "function") {
                                        return acc + resolvePrices[serv.key](serv, prod.product)
                                    }
                                }, 0);
                                prod.totalPrice = summa * prod.totalAmount
                                return true;
                            }
                        })
                    }
                    return true
                }
            })
        },
        SET_AMOUNT(state, {id, amount}) {
            state.products.some(prod => {
                if (prod.id === id) {
                    console.log(amount);
                    //prod.totalAmount = amount > prod.stock ? prod.stock : amount;
                    prod.totalAmount = amount;
                    let summa = prod.services.reduce((acc, serv) => {
                        if (serv.price) return acc + serv.price
                        else if (typeof resolvePrices[serv.key] === "function") {
                            return acc + resolvePrices[serv.key](serv, prod.product)
                        }
                    }, 0);
                    prod.totalPrice = summa * prod.totalAmount
                    return true
                }
            })
        },
        UPDATE_PRODUCTS(state, products) {
            state.products = [];
            products.forEach(prod => {
                const prodSupply = {
                    id: prod.id,
                    product: prod,
                    totalAmount: null,
                    totalPrice: 0,
                    services: [],
                    servVariant: null,
                }

                if (Array.isArray(prod.stockff)) {
                    prodSupply.amountBySize = prod.stockff.map(sff => ({
                        size: sff.size,
                        amount: null,
                        stock: sff.stock
                    }));
                } else prodSupply.stock = prod.stockff;

                state.products.push(prodSupply)
            })
        },
        DEL_PRODUCT_FROM_SUPPLY(state, productId) {
            state.products.some((prod, i) => {
                if (prod.id === productId) {
                    state.products.splice(i, 1);
                    return true;
                }
            })
        },
        ADD_PRODUCT_TO_SUPPLY(state, product) {
            const prodSupply = {
                id: product.id,
                product: product,
                totalAmount: null,
                totalPrice: 0,
                services: [],
                servVariant: null,
            }

            if (Array.isArray(product.stockff)) {
                prodSupply.amountBySize = product.stockff.map(sff => ({
                    size: sff.size,
                    amount: null,
                    stock: sff.stock
                }));
            } else prodSupply.stock = product.stockff;

            state.products.push(prodSupply)
        },
        ADD_SERVICE_TO_SUPPLY(state, {prodId, service, servVariant}) {
            state.products.some(prod => {
                if (prod.id === prodId) {
                    prod.services.some((serv, i) => {
                        if (serv.id === service.id) {
                            prod.services.splice(i, 1);
                            return true
                        }
                    });
                    let servToSupply = {
                        id: service.id,
                        priority: service.priority,
                        key: service.key,
                        label: service.label,
                        description: service.description,
                        price: servVariant ? servVariant.price : service.price,
                        type: service.type,
                        amountToSet: 0,
                        servVariant,
                        coefficient: service.coefficient,
                    };

                    prod.services.push(servToSupply);
                    let summa = prod.services.reduce((acc, curr) => {
                        return acc + curr.price
                    }, 0);
                    prod.totalPrice = summa * prod.totalAmount
                    return true
                }
            })
        },
        DEL_SERVICE_TO_SUPPLY(state, {prodId, servId}) {
            console.log(servId)
            state.products.some((prod, i) => {
                if (prod.id === prodId) {
                    for (let i = 0; i < prod.services.length; i++) {
                        const serv = prod.services[i];
                        if (serv.id === servId) {
                            prod.services.splice(i, 1);
                            let summa = prod.services.reduce((acc, curr) => {
                                return acc + curr.price
                            }, 0);
                            prod.totalPrice = summa * prod.totalAmount
                            return true;
                        }
                    }
                }
            })
        },
        DEL_SERVICE_FROM_ALL_PROD(state, servId) {
            state.products.forEach((prod, i) => {
                for (let i = 0; i < prod.services.length; i++) {
                    const serv = prod.services[i];
                    if (serv.id === servId) {
                        prod.services.splice(i, 1);
                        let summa = prod.services.reduce((acc, curr) => {
                            return acc + curr.price
                        }, 0);
                        prod.totalPrice = summa * prod.totalAmount
                    }
                }
            })
        },
    },
    actions: {
        addProductsToSupply({commit, dispatch}, products) {
            commit('UPDATE_PRODUCTS', products)
        },
        totalAmountUpdate({commit, dispatch}, amount) {
            commit('SET_AMOUNT_BY_SIZE', amount)
        },
        setAmount({commit}, amount) {
            commit('SET_AMOUNT', amount);
        },
        addServiceToProductSupply({commit}, servData) {
            commit('ADD_SERVICE_TO_SUPPLY', servData)
        },
        delServiceToProductSupply({commit}, servData) {
            commit('DEL_SERVICE_TO_SUPPLY', servData)
        },
        delServiceToAllProductSupply({commit}, {servId}) {
            commit('DEL_SERVICE_FROM_ALL_PROD', servId)
        },
        setFirstPacking({commit}, servData = {servVariant: {}, prodId: 0, service: {}}) {
            commit('ADD_SERVICE_TO_SUPPLY', servData);
        },
        setSecondPacking({commit}, servData = {servVariant: {}, prodId: 0, service: {}}) {
            commit('ADD_SERVICE_TO_SUPPLY', {id: servData})
        },
        delProductFromSupply({commit}, productId) {
            commit('DEL_PRODUCT_FROM_SUPPLY', productId);
        },
        addProductToSupply({commit}, product) {
            commit('ADD_PRODUCT_TO_SUPPLY', product);
        },
    },
}


