
export default {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        async sendProducts({commit, dispatch}, params) {

        },
    },
}
