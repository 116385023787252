import axios from "axios";
import {sanitizeHtml} from "bootstrap/js/src/tools/sanitizer";

export default {
    namespaced: true,

    state: {
        orders: [],
        selectedOrders: [],
        selectedOrdersRm: [],
        selectedOrdersRegister: {},
        findText: '',
        pageSize: 10,
        loadOrdersState: false,
        optionsPageSize: [5, 10, 50, 100],
        fieldsFindText: ["date", "incomeid", "finished_price", "gnumber", "oblast_okrug_name", "region_name", "srid", "vendorCode", "warehousename",],
        filterState: ["Все", "получен", "отказ", "в пути",],
        filterBrand: [],
        filterObject: [],
        selectedBrand: null,
        selectedState: null,
        selectedObject: null,
        selfRansomFilter: null,
    },

    getters: {
        getOrders(state, getters) {
            return state.orders.filter(order => {
                if (state.selectedState !== null && state.selectedState !== "Все" && state.selectedState !== order.status) return false;
                if (state.selectedBrand !== null && state.selectedBrand !== "Все" && state.selectedBrand !== order.brand) return false;
                if (state.selectedObject !== null && state.selectedObject !== "Все" && state.selectedObject !== order.object) return false;
                if (state.selfRansomFilter === 1 && order.selfRansom === 0) return false;
                for (const orderKey of state.fieldsFindText) {
                    if (String(order[orderKey]).toLowerCase().includes(state.findText.toLowerCase())) return true;
                }
            })
        }
    },

    mutations: {
        CLEAR_CURRENT_SHIPMENT(state) {
            state.currentShipment = {}
        },

        ADD_ORDERS(state, orders) {
            state.orders = orders
        },

        CLEAR_ALL_FILTERS(state) {
            state.findText = '';
            state.pageSize = 10;
            state.selectedBrand = null;
            state.selectedState = null;
            state.selectedObject = null;
            state.selfRansomFilter = null
        },

        ADD_SRID_TO_SMV(state, {srid, smv}) {
            if (state.selectedOrdersRm.indexOf(srid) === -1) {
                if (smv) state.selectedOrdersRm.push(srid); else state.selectedOrders.push(srid);
            }
        },

        RM_SRID_TO_SMV(state, {srid, smv}) {
            if (smv) {
                let i = state.selectedOrdersRm.indexOf(srid);
                if (i !== -1) state.selectedOrdersRm.splice(i, 1);
            } else {
                let i = state.selectedOrders.indexOf(srid);
                if (i !== -1) state.selectedOrders.splice(i, 1);
            }
        },


        SET_SELF_RANSOM_FILTER(state, val) {
            val ? state.selfRansomFilter = 1 : state.selfRansomFilter = null
        },

        SET_FIND_TEXT(state, data) {
            state.findText = data
        },
    },

    actions: {
        async sendCurrentShipment({commit, state, dispatch, rootState}, currentShipment) {
            currentShipment.products = rootState.products.selectedProductsId
            await axios.post('/ff/create_shipment', currentShipment);
        },


        setFilters({state}, orders) {
            let filterBrand = {}, filterObject = {};
            for (const order of orders) {
                if (order.brand) filterBrand[order.brand] = 1
                if (order.object) filterObject[order.object] = 1
            }
            state.filterBrand = Object.keys(filterBrand);
            state.filterBrand.push('Все');
            state.filterObject = Object.keys(filterObject);
            state.filterObject.push('Все');
        },

        async loadOrders({commit, dispatch, state}, data) {
            try {
                let orders = await axios.post('orders/get_orders', data || {})
                if (orders.status === 200) {
                    dispatch('setFilters', orders.data);
                    commit('ADD_ORDERS', orders.data);
                    state.loadOrdersState = true;
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },

        async setFindText({commit, dispatch}, data) {
            commit('SET_FIND_TEXT', data)
        },

        async setSelectedBrand({state}, data) {
            state.selectedBrand = data;
        },

        async setSelectedState({state}, data) {
            state.selectedState = data;
        },

        async setSelectedObject({state}, data) {
            state.selectedObject = data;
        },


        async setPageSize({state}, data) {
            state.pageSize = data;
        },

        async sendSelfRansom({state, commit, dispatch}, data) {
            if (state.selectedOrders.length) {
                dispatch('sendSridASSelRansom', {
                    addSrid: state.selectedOrders
                })
            }
        },

        async sendSelfRansomRm({state, commit, dispatch}) {
            if (state.selectedOrdersRm.length) {
                dispatch('sendSridASSelRansom', {
                    delSrid: state.selectedOrdersRm
                })
            }
        },

        async updateSelfRansom({commit, dispatch, state}, {addSridSuccess, delSridSuccess}) {
            for (const srid of delSridSuccess) {
                let order = state.orders.find(or => or.srid === srid);
                if (order) order.selfRansom = 0
            }

            for (const srid of addSridSuccess) {
                let order = state.orders.find(or => or.srid === srid);
                if (order) order.selfRansom = 1
            }
        },

        async sendSridASSelRansom({commit, dispatch, state}, data) {
            try {
                let {addSrid, delSrid} = data;
                let orders = await axios.post('ff/add_self_ransom', {addSrid, delSrid})
                if (orders.status === 200) {
                    dispatch('updateSelfRansom', orders.data);
                    state.selectedOrdersRm = [];
                    state.selectedOrders = [];

                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },

        async selectSridOrder({state, commit, dispatch}, {val, srid, smv}) {
            if (val) commit('ADD_SRID_TO_SMV', {srid, smv}); else commit('RM_SRID_TO_SMV', {srid, smv});
        },

        async setSelfRansomFilter({state, commit, dispatch}, data) {
            commit('SET_SELF_RANSOM_FILTER', data);
        },

        async clear({state, commit, dispatch}, data) {
            commit('CLEAR_ALL_FILTERS');
        },

        async selectAllSridOrder({getters, state, commit, dispatch}, val) {
            if (val) {
                for (const order of getters.getOrders) {
                    commit('ADD_SRID_TO_SMV', {srid: order.srid, smv: order.selfRansom});
                }
            } else {
                for (const order of getters.getOrders) {
                    commit('RM_SRID_TO_SMV', {srid: order.srid, smv: order.selfRansom});
                }
            }
        },
    },
}
