import axios from 'axios';
import cookieParse from './cookieParse'

export default {
    namespaced: true,
    state: {
        partKeyWb: '',
        keyName: '',
        dateEndKey: '',
        isTestKey: false,
        scoopsList: [],
        scoopsKey: []
    },
    getters: {},
    mutations: {
        SET_WB_KEY(state, data) {
            state.partKeyWb = data.partKey;
            state.keyName = data.keyName;
            state.dateEndKey = data.dateEndKey;
            state.isTestKey = data.isTestKey;
            state.scoopsKey = data.scoops;
        },
        SET_SCOOPS_LIST(state, data) {
            state.scoopsList = data;
        },
    },
    actions: {
        async sendKey({commit, dispatch}, {key, keyName}) {
            try {
                let res = await axios.post('/keysmp/add_wb_key', {key, keyName});
                return res.status
            } catch (e) {
                console.log(e)
                return false
            }
        },

        async sendCookieKey({commit, dispatch}, curl) {
            try {
                let res = await axios.post('/keysmp/add_cookie', {
                    key: cookieParse(curl)
                });
                return res.status
            } catch (e) {
                console.log(e)
                return false
            }
        },

        async sendTableId({commit, dispatch}, key) {
            try {
                let res = await axios.post('/keysmp/add_google_table_id', {
                    key
                });
                return res.status
            } catch (e) {
                console.log(e)
                return false
            }
        },

        async loadScoops({commit, dispatch}) {
            let res = await axios.get('/keysmp/list_key_scoops_wb');
            if (res.status === 200) commit('SET_SCOOPS_LIST', res.data.scoops);
        },

        async loadInfoKey({commit}) {
            try {
                let res = await axios.get('/keysmp/get_key_info_wb');
                if (res.status === 200 && res.data.keyInfo) commit('SET_WB_KEY', res.data.keyInfo);
            } catch (e) {
                console.log(e)
                return false
            }
        },
    },
}
