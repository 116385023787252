<template>
  <input
      class="ff-input"
      v-bind:value="modelValue"
      @input="setValue"
  />
</template>

<script>
export default {
  name: "FInputDef",
  data() {
    return {
      localValue: null
    }
  },
  props: {
    modelValue: 0,
    maxValue: Number,
  },
  methods: {
    setValue(event) {
      const value = event.target.value;
      if (this.maxValue && value > this.maxValue) {
        this.localValue = this.maxValue;
        this.$emit('input', this.maxValue);
      } else {
        this.localValue = value;
        this.$emit('input', value);
      }
    }
  }
}
</script>

<style scoped>
.ff-input {
  outline: none;
  border: 1px solid #D8D6DE;
  border-radius: 6px;
  padding: 0.4rem 1rem;
  width: 80px;
  color: #6e6b7b;
}
.ff-input::placeholder {
  font-size: 12px;
  color: #B9B9C3;
}
</style>