<template>
  <div class="select-pack-container position-relative">
    <b-dropdown
        :toggle-class="'p-0 d-flex no-size align-items-center'"
        variant="flat-secondary"
    >
      <template #button-content>
        <div class="variant-pack zip d-flex justify-content-between align-items-start">
          <span class="variant-pack-name">{{ localState ? selectedPack.label : currentPack.label }}</span>
          <b-img style="height: 48px" v-if="currentPack.img || selectedPack.img "
                 :src=" localState  ? selectedPack.img :  currentPack.img"/>
        </div>
      </template>
      <b-dropdown-item
          v-for="(pack, i) in getFirstPack"
          :key="i"
          @click="selectPack(pack)"
      >
        <div class="variant-pack zip d-flex justify-content-between align-items-start w-100">
          <span style="white-space: normal">{{ pack.label }}</span>
          <b-img style="height: 48px" v-if="pack.img" :src="pack.img"/>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BTable,
  BButton,
  BSpinner,
  BCardText,
  BLink,
  BFormInput,
  BFormSelect,
  BFormDatepicker,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import {mapActions, mapState} from "vuex";

export default {
  components: {
    BCard,
    BImg,
    BTable,
    BButton,
    BSpinner,
    BCardText,
    BLink,
    BFormInput,
    BFormSelect,
    BFormDatepicker,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
  },
  name: "SelectPack",
  methods: {
    selectPack(pack) {
      this.selectedPack = pack
      pack.parentName = 'firstPack'
      this.$emit('input', pack);
    }
  },
  computed: {
    getFirstPack() {
      return this.listServices.find(serv => serv.name === 'firstPack').options
    },
    ...mapState({listServices: state => state.services.listServices,}),
  },
  props: {
    currentPack: {
      type: Object,
      default() {
        return {
          "name": "without",
          "label": "без упаковки",
          "description": "",
          "price": 0,
        }
      },
    },
    localState: Boolean
  },
  inject: {},
  data() {
    return {
      show: false,
      selectedPack: {
        "name": "without",
        "label": "без упаковки",
        "description": "",
        "price": 0,
      }
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

.variant-pack {
  margin: 10px 0;
  cursor: pointer;
}

.variant-pack-card {
  z-index: 10;
}


.variant-pack-name {
  padding-right: 20px;
  white-space: normal;
  text-align: start;
}

</style>