import axios from "axios";

export default {
    namespaced: true,
    state: {
        productsToAdd: [],
        products: [],
        selectedProducts: [],
        trigger: 1,
        popUpState: false
    },

    getters: {
        getNewProducts(state, getters, rootState) {
            return rootState.nomenclatures.products.filter(product => !state.products.find(prod1 => +product.nmID === +prod1.nmID))
        },
    },

    mutations: {
        ADD_ALL_PRODUCTS(state, data) {
            state.selectedProducts = data
        },

        ADD_PRODUCT_PURCHASE(state, prod) {
            state.products.push(prod);
        },

        SET_PRODUCT_PURCHASE(state, prod) {
            prod.forEach(item => {
                item._showDetails = false
            })
            state.products = prod
        },

        ADD_PRODUCT(state, product) {
            let productInPurchase = state.products.find(prodIn => +prodIn.nmID === +product.nmID)
            console.log('productsInPurchase', productInPurchase)
            if (productInPurchase) {
                for (const sizeInPurchase of productInPurchase.sizes) {
                    let size = product.sizes.find(item => +sizeInPurchase.skus[0] === +item.barcode);
                    if (size) {
                        console.log(' sizeInPurchase.amount before', sizeInPurchase)
                        sizeInPurchase.amount += +size.amount
                        console.log(' sizeInPurchase.amount after', sizeInPurchase)
                        return
                    }
                }
            } else state.products.push(product);
        },

        RM_PRODUCT(state, nmId) {
            let i = state.selectedProducts.indexOf(nmId);
            if (i !== -1) state.selectedProducts.splice(i, 1)
        },

        SET_AMOUNT_BAY_SIZE(state, {amount, nmID, chrtID}) {
            let size = state.products.find(prod => prod.nmID === nmID)?.sizes?.find(size => size.chrtID === chrtID);
            if (size) {
                size.amount = amount;
                size.diffAmount = Math.round((amount / size.lastPurchaseAmount * 100) - 100);
            }
        },

        SET_PRICE_BAY_SIZE(state, {price, nmID, chrtID}) {
            let size = state.products.find(prod => prod.nmID === nmID)?.sizes?.find(size => size.chrtID === chrtID);
            if (size) {
                size.price = price;
                size.diffPercent = Math.round((price / size.lastPurchasePrice * 100) - 100);
            }
        },

        RM_ALL_PRODUCTS(state, product) {
            state.selectedProducts = [];
        },

        SET_PRODUCTS_TO_ADD(state, product) {
            state.productsToAdd = product;
        },

        CLEAR_PRODUCTS_TO_ADD(state, product) {
            state.productsToAdd = [];
        },

        SET_PURCHASES(state, data) {
            state.purchases = data
        },
    },

    actions: {
        async triggering({state}) {
            state.trigger++
        },

        async loadProductsPurchase({commit}, {procurementId}) {
            try {
                let products = await axios.post('/purchase/get_products_purchase', {
                    procurementId
                });
                if (products.status === 200) {
                    products = products.data
                    commit('SET_PRODUCT_PURCHASE', products);
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },

        async saveProduct({commit}, {procurementId, products, isAmountAdd}) {
            try {
                let success = await axios.post('/purchase/add_products', {
                    procurementId,
                    products,
                    isAmountAdd
                });

                if (success.status === 200) {
                    success = success.data
                    return success
                }
            } catch (e) {
                console.log(e)
            }

            return false
        },

        async addAllProductsPurchases({commit, dispatch}, nmIDs) {
            commit('ADD_ALL_PRODUCTS', nmIDs);
        },

        async addProductToPurchase({commit, dispatch}, nmID) {
            commit('ADD_PRODUCT', nmID);
        },

        async rmProductFromPurchase({commit, dispatch}, nmID) {
            commit('RM_PRODUCT', nmID);
        },

        async rmAllProductFromPurchase({commit, dispatch}) {
            commit('RM_ALL_PRODUCTS');
        },

        includeProducts({state, commit, dispatch, rootState}) {
            for (const product of rootState.nomenclatures.products) {
                console.log(product)
                if (rootState.nomenclatures.selectedProductsNmID.includes(product.nmID) && product.sizes) {
                    product.sizes = product.sizes.map(size => ({
                        wbSize: size.wbSize,
                        techSize: size.techSize,
                        price: 0,
                        amount: 0,
                        chrtID: size.chrtId,
                        skus: size.skus
                    }));
                    commit('ADD_PRODUCT_PURCHASE', {
                        url: product.imgMain,
                        nmID: product.nmID,
                        sizes: product.sizes,
                        title: product.subjectName,
                        vendorCode: product.vendorCode,
                        brand: product.brand,
                        _showDetails: false
                    });
                }
            }
            dispatch('nomenclatures/clearsSelectNmId', null, {root: true})
        },

        async setProductsToAdd({commit}, products) {
            commit('SET_PRODUCTS_TO_ADD', products);
        },

        async clearProductsToAdd({commit}, products) {
            commit('CLEAR_PRODUCTS_TO_ADD', products);
        },

        async prependProducts({state, commit, dispatch, rootActions}, {products, purchaseId, isAmountAdd}) {
            dispatch('saveProduct', {procurementId: purchaseId, products, isAmountAdd})
        },

        async setAmountBaySize({state, commit, dispatch, rootState}, amount) {
            commit('SET_AMOUNT_BAY_SIZE', amount);
            dispatch('triggering');
        },

        async setPriceBaySize({state, commit, dispatch, rootState}, price) {
            commit('SET_PRICE_BAY_SIZE', price);
            dispatch('triggering');
        },

        closePopUp({state}) {
            state.popUpState = false
        }
    },
}
