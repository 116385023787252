import axios from "axios";

export default {
    namespaced: true,
    state: {
        topRows: {},
        salesWB: {},
        revenueWB: {},
        realizedCost: {},
        operatingExpenses: {},
        transactionsYears: {}
    },

    getters: {},

    mutations: {
        SET_PNL_REPORTS(state, data) {
            let {transactionsYears, topRows, salesWB, revenueWB, realizedCost, operatingExpenses} = data;
            state.topRows = topRows;
            state.salesWB = salesWB;
            state.revenueWB = revenueWB;
            state.realizedCost = realizedCost;
            state.operatingExpenses = operatingExpenses;
            state.transactionsYears = transactionsYears;
        },
    },

    actions: {
        async getPnlReport({commit, dispatch}) {
            try {
                let pnl_report_rows = await axios.get('/econ/get_pnl_report');
                if (pnl_report_rows.status === 200) {
                    pnl_report_rows = pnl_report_rows.data;
                    commit('SET_PNL_REPORTS', pnl_report_rows);
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },
    },
}
