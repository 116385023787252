export default {
    namespaced: true,
    state: {
        delivery: [
            {
                "id": 2,
                "products": [
                    {
                        "id": 8,
                        "firstImg": "https://basket-03.wb.ru/vol358/part35868/35868169/images/big/4.jpg",
                        "name": "name",
                        "article": "article",
                        "brand": "brand",
                        "amountBySize": {
                            "amountUser": 5,
                            "amount": 5,
                            "techSize": "S",
                            "wbSize": "45",
                            "skus": [
                                "123456789123456789"
                            ]
                        }
                    }
                ],
                "statuses": {
                    "create": {
                        "label": "создана",
                        "date": new Date().toLocaleDateString(),
                        "docName": "",
                        "docType": ""
                    },
                    "handling": {
                        "label": "обработка",
                        "date": new Date().toLocaleDateString(),
                        "docName": "",
                        "docType": ""
                    },
                    "packaging": {
                        "label": "упаковка",
                        "date": new Date().toLocaleDateString(),
                        "docName": "",
                        "docType": ""
                    },
                    "delivery": {
                        "label": "поставка на мп",
                        "date": new Date().toLocaleDateString(),
                        "docName": "",
                        "docType": ""
                    },
                    "accepted": {
                        "label": "принято на мп",
                        "date": "",
                        "docName": "",
                        "docType": ""
                    }
                },
                "marketplace": {
                    "id": 1,
                    "warehouseId": 1
                },
                "currentStatus": "delivery",
                "date": new Date().toLocaleDateString(),
                "message": {
                    "type": "error",
                    "value": "Ошибка тестовая"
                },
                "createAt": new Date().toLocaleDateString(),
                "onlyFreeAcceptanceMP": true,
                "noTransit": true,
                "services": {
                    "12": [
                        {
                            "serviceId": 10,
                            "values": [
                                {
                                    "name": "Количество в комплекте",
                                    "value": "5"
                                }
                            ]
                        }
                    ]
                },
                "costOfWork": 0,
                "volume": "string",
                amountBox: 5,
                amountPallet: 0,
                totalVolume: 1,
            },
            {
                "id": 23,
                "products": [
                    {
                        "id": 8,
                        "firstImg": "https://basket-03.wb.ru/vol358/part35868/35868169/images/big/4.jpg",
                        "name": "name",
                        "article": "article",
                        "brand": "brand",
                        "amountBySize": {
                            "amountUser": 5,
                            "amount": 5,
                            "techSize": "S",
                            "wbSize": "45",
                            "skus": [
                                "123456789123456789"
                            ]
                        }
                    }
                ],
                "statuses": {
                    "create": {
                        "label": "создана",
                        "date": new Date().toLocaleDateString(),
                        "docName": "",
                        "docType": ""
                    },
                    "handling": {
                        "label": "обработка",
                        "date": "",
                        "docName": "",
                        "docType": ""
                    },
                    "packaging": {
                        "label": "упаковка",
                        "date": "",
                        "docName": "",
                        "docType": ""
                    },
                    "delivery": {
                        "label": "поставка на мп",
                        "date": "",
                        "docName": "",
                        "docType": ""
                    },
                    "accepted": {
                        "label": "принято на мп",
                        "date": "",
                        "docName": "",
                        "docType": ""
                    }
                },
                "marketplace": {
                    "id": 2,
                    "warehouseId": 1
                },
                "currentStatus": "handling",
                "date": new Date().toLocaleDateString(),
                "message": {
                    "type": "warning",
                    "value": "Предупреждение тестовое"
                },
                "createAt": new Date().toLocaleDateString(),
                "onlyFreeAcceptanceMP": 1,
                "noTransit": false,
                "services": {
                    "12": [
                        {
                            "serviceId": 10,
                            "values": [
                                {
                                    "name": "Количество в комплекте",
                                    "value": "5"
                                }
                            ]
                        }
                    ]
                },
                "costOfWork": 4890,
                "volume": "string",
                amountBox: 5,
                amountPallet: 0,
                totalVolume: 1,
            },
            {
                "id": 3456,
                "products": [
                    {
                        "id": 8,
                        "firstImg": "https://basket-03.wb.ru/vol358/part35868/35868169/images/big/4.jpg",
                        "name": "name",
                        "article": "article",
                        "brand": "brand",
                        "amountBySize": {
                            "amountUser": 5,
                            "amount": 5,
                            "techSize": "S",
                            "wbSize": "45",
                            "skus": [
                                "123456789123456789"
                            ]
                        }
                    }
                ],
                "statuses": {
                    "create": {
                        "label": "создана",
                        "date": new Date().toLocaleDateString(),
                        "docName": "",
                        "docType": ""
                    },
                    "handling": {
                        "label": "обработка",
                        "date": new Date().toLocaleDateString(),
                        "docName": "",
                        "docType": ""
                    },
                    "packaging": {
                        "label": "упаковка",
                        "date": new Date().toLocaleDateString(),
                        "docName": "",
                        "docType": ""
                    },
                    "delivery": {
                        "label": "поставка на мп",
                        "date": new Date().toLocaleDateString(),
                        "docName": "",
                        "docType": ""
                    },
                    "accepted": {
                        "label": "принято на мп",
                        "date":  new Date().toLocaleDateString(),
                        "docName": "",
                        "docType": ""
                    }
                },
                "marketplace": {
                    "id": 1,
                    "warehouseId": 2
                },
                "currentStatus": "accepted",
                "date": new Date().toLocaleDateString(),
                "message": {},
                "createAt": new Date().toLocaleDateString(),
                "onlyFreeAcceptanceMP": true,
                "noTransit": true,
                "services": {
                    "12": [
                        {
                            "serviceId": 10,
                            "values": [
                                {
                                    "name": "Количество в комплекте",
                                    "value": "5"
                                }
                            ]
                        }
                    ]
                },
                "costOfWork": 0,
                "volume": "string",
                amountBox: 5,
                amountPallet: 0,
                totalVolume: 1,
            },
        ],
        marketplaces: [
            {
                id: 1,
                name: 'Wildberries',
                shortName: 'wb',
                color: '#9747FF',
                warehouses: {
                    "1": {
                        name: 'Краснодар'
                    },
                    "2": {
                        name: 'Склад'
                    }
                }
            },
            {
                id: 2,
                name: 'Ozon',
                shortName: 'ozon',
                color: '#2e56ef',
                warehouses: {
                    "1": {
                        name: 'Москва'
                    }
                }
            }
        ],
        currentDelivery: {
            products: [

            ]
        }
    },
    getters: {},
    mutations: {},
    actions: {
        async loadDelivery({commit, dispatch}, params) {

        },
        async getMPByIDs({commit, dispatch, state}, {idm, idw}) {
            return state.marketplaces.find(mp=> +mp.id === +idm);
        },
    },
}
