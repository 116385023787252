import axios from "axios";

let t = {
    withColumnValue: {
        product: 250,
        order_percent: 60,
        sales_percent: 60,
        sales_cost_price: 85,
        val_value_percent: 60,
        expenses_mp_percent: 60,
        cost_price_percent: 60,
        promo_percent: 60,
        promo_summa: 90,
        speed_sales: 105,
        /*        drr: 40,*/
        forecast: 110,
        sub_sort: 110,
        pay_to_finaccount: 120,
    },
    listFields: [
        {
            key: 'product',
            label: '',
            stickyColumn: true,
            tdClass: 'product-cell'
        },
        {
            key: 'order_chart',
            label: 'динамика',
            tdClass: 'orders_cell'
        },
        {
            key: 'order_amount',
            label: 'шт.',
            tdClass: 'orders_cell'
        },
        {
            key: 'order_summ',
            label: 'руб',
            tdClass: 'orders_cell'
        },
        {
            key: 'order_percent',
            label: '%',
            tdClass: 'orders_cell'
        },
        {
            key: 'sales_chart',
            label: 'динамика',
            tdClass: 'sales_cell'
        },
        {
            key: 'sales_amount',
            label: 'шт',
            tdClass: 'sales_cell'
        },
        {
            key: 'sales_summ',
            label: 'руб',
            tdClass: 'sales_cell'
        },
        {
            key: 'sales_percent',
            label: '%',
            tdClass: 'sales_cell'
        },
        {
            key: 'averagebill',
            label: 'руб',
            tdClass: 'sales_cell'
        },
        {
            key: 'averageBillOrders',
            label: 'руб',
            tdClass: 'sales_cell'
        },
        {
            key: 'summa_val_value',
            label: 'руб',
            tdClass: 'sales_cell'
        },
        {
            key: 'unit_val_value',
            label: 'руб/шт',
            tdClass: 'sales_cell'
        },
        {
            key: 'val_value_percent',
            label: '%',
            tdClass: 'sales_cell'
        },
        {
            key: 'pay_to_finaccount',
            label: 'руб',
            tdClass: 'sales_cell'
        },
        {
            key: 'quantity',
            label: 'шт',
            tdClass: 'sales_cell'
        },
        {
            key: 'summa',
            label: 'руб',
            tdClass: 'sales_cell'
        },
        {
            key: 'transfer_amount',
            label: 'руб',
            tdClass: 'sales_cell'
        },
        {
            key: 'cost_price',
            label: 'итого р.',
            tdClass: 'cost-price'
        },
        {
            key: 'cost_price_unit',
            label: 'руб/шт',
            tdClass: 'cost-price'
        },
        {
            key: 'cost_price_percent',
            label: '%',
            tdClass: 'cost-price'
        },
        {
            key: 'sales_cost_price',
            label: 'итого р.',
            tdClass: 'cost-price'
        },
        {
            key: 'sales_cost_price_unit',
            label: 'руб/шт',
            tdClass: 'cost-price'
        },
        {
            key: 'sales_cost_price_percent',
            label: '%',
            tdClass: 'cost-price'
        },
        {
            key: 'expenses_mp_sum',
            label: 'руб',
            tdClass: 'td-purpur-white'
        },
        {
            key: 'expenses_mp_unit',
            label: 'руб/шт',
            tdClass: 'td-purpur-white'
        },
        {
            key: 'expenses_mp_percent',
            label: '%',
            tdClass: 'td-purpur-white'
        },
        {
            key: 'delivery',
            label: 'руб',
            tdClass: 'td-purpur-white'
        },
        {
            key: 'delivery_unit',
            label: 'руб/шт',
            tdClass: 'td-purpur-white'
        },
        {
            key: 'storage_cost',
            label: 'руб',
            tdClass: 'td-purpur-white'
        },
        {
            key: 'storage_fee_item',
            label: 'руб',
            tdClass: 'td-purpur-white'
        },
        {
            key: 'penalty',
            label: 'руб',
            tdClass: 'td-purpur-white'
        },
        {
            key: 'additional_payment',
            label: 'руб',
            tdClass: 'td-purpur-white'
        },
        {
            key: 'cost_acceptance',
            label: 'руб',
            tdClass: 'td-purpur-white'
        },
        {
            key: 'deduction',
            label: 'руб',
            tdClass: 'td-purpur-white'
        },
        {
            key: 'promo_total',
            label: 'руб',
            tdClass: 'td-ping-white'
        },
        {
            key: 'promo_unit',
            label: 'руб/шт',
            tdClass: 'td-ping-white'
        },
        {
            key: 'promo_percent',
            label: '%',
            tdClass: 'td-ping-white'
        },
        {
            key: 'promo_summa',
            label: 'счет',
            tdClass: 'td-ping-white'
        },
        {
            key: 'promo_summa_balance',
            label: 'баланс',
            tdClass: 'td-ping-white'
        },
        /*        {
                    key: 'drr',
                    label: '%',
                    tdClass: 'td-ping-white'
                },*/
        {
            key: 'sales_promo_total',
            label: 'руб',
            tdClass: 'td-ping-white'
        },
        {
            key: 'sales_promo_unit',
            label: 'руб/шт',
            tdClass: 'td-ping-white'
        },
        {
            key: 'sales_promo_percent',
            label: '%',
            tdClass: 'td-ping-white'
        },
        {
            key: 'sales_promo_summa',
            label: 'счет',
            tdClass: 'td-ping-white'
        },
        {
            key: 'sales_promo_summa_balance',
            label: 'баланс',
            tdClass: 'td-ping-white'
        },
        /*        {
                    key: 'sales_drr',
                    label: '%',
                    tdClass: 'td-ping-white'
                },*/
        {
            key: 'dues',
            label: 'руб'
        },
        {
            key: 'speed_sales',
            label: 'шт'
        },
        {
            key: 'surplus',
            label: 'шт'
        },
        {
            key: 'inwaytoclient',
            label: 'шт'
        },
        {
            key: 'inwayfromclient',
            label: 'шт'
        },
        {
            key: 'forecast',
            label: 'шт'
        },
        {
            key: 'sub_sort',
            label: 'подсорт'
        },
        {
            key: 'enough_for',
            label: 'дней'
        },
    ],
    sets: {
        orders: {
            set: [
                'product',
                'order_chart',
                'order_amount',
                'order_summ',
                'order_percent',
                'sales_chart',
                'sales_amount',
                'sales_summ',
                'sales_percent',
                'averageBillOrders',
                'sales_cost_price',
                'sales_cost_price_unit',
                'sales_cost_price_percent',
                'sales_promo_summa',
                'sales_promo_summa_balance',
                'sales_promo_total',
                'sales_promo_unit',
                'sales_promo_percent',
                /*                'sales_drr',*/

                'surplus',
                'inwaytoclient',
                'inwayfromclient',
                'forecast',
                'sub_sort',
                'enough_for',
            ],
            name: 'Заказы',
        },
        realization: {
            set: [
                'product',
                'averagebill',
                'summa_val_value',
                'unit_val_value',
                'val_value_percent',
                'pay_to_finaccount',
                'quantity',
                'summa',
                'transfer_amount',
                'expenses_mp_sum',
                'expenses_mp_unit',
                'expenses_mp_percent',
                'delivery',
                'delivery_unit',
                'storage_cost',
                'storage_fee_item',
                'penalty',
                'additional_payment',
                'cost_price',
                'cost_price_unit',
                'cost_price_percent',
                'cost_acceptance',
                'deduction',
                'promo_summa',
                'promo_summa_balance',
                'promo_total',
                'promo_unit',
                'promo_percent',
                /*'drr',*/
                'dues',
                'speed_sales',
                'surplus',
                'inwaytoclient',
                'inwayfromclient',
                'enough_for',
            ],
            name: 'Реализация',
        }
    },
    warehouses: [],
    mainHeaderRelativity: [
        {
            name: 'product',
            label: 'Продукт',
            children: [
                'product',
            ]
        },
        {
            name: 'order',
            label: 'Заказано',
            children: [
                'order_percent',
                'order_chart',
                'order_amount',
                'order_summ',
            ]
        },
        {
            name: 'sales',
            label: 'Выкуплено',
            children: [
                'sales_percent',
                'sales_amount',
                'sales_chart',
                'sales_summ',
            ]
        },
        {
            name: 'averagebill',
            label: 'Ср. чек',
            children: [
                'averagebill'
            ]
        },
        {
            name: 'averageBillOrders',
            label: 'Ср. чек',
            children: [
                'averageBillOrders'
            ]
        },
        {
            name: 'pay_to_finaccount',
            label: 'К оплате на р/с',
            children: [
                'pay_to_finaccount'
            ]
        },
        {
            name: 'real',
            label: 'Продажи фин-отчет',
            children: [
                'quantity',
                'summa',
            ]
        },
        {
            name: 'transfer_amount',
            label: 'Перечис.',
            children: [
                'transfer_amount'
            ]
        },
        /*        {
                    name: 'drr',
                    label: 'ДРР',
                    children: [
                        'drr'
                    ]
                },*/
        /*        {
                    name: 'sales_drr',
                    label: 'ДРР',
                    children: [
                        'sales_drr'
                    ]
                },*/
        {
            name: 'expenses_mp',
            label: 'Расходы МП',
            children: [
                'expenses_mp_sum',
                'expenses_mp_unit',
                'expenses_mp_percent',
            ]
        },
        {
            name: 'delivery',
            label: 'Логистика',
            children: [
                'delivery',
                'delivery_unit'
            ]
        },
        {
            name: 'storage_cost',
            label: 'Хранение',
            children: [
                'storage_cost'
            ]
        },
        {
            name: 'storage_fee_item',
            label: 'Хранения Реал',
            children: [
                'storage_fee_item'
            ]
        },
        {
            name: 'penalty',
            label: 'Сумма штрафов',
            children: [
                'penalty'
            ]
        },
        {
            name: 'additional_payment',
            label: 'Доплаты',
            children: [
                'additional_payment'
            ]
        },
        {
            name: 'cost_acceptance',
            label: 'Приемка',
            children: [
                'cost_acceptance'
            ]
        },
        {
            name: 'deduction',
            label: 'Прочие удержания',
            children: [
                'deduction'
            ]
        },
        {
            name: 'promo',
            label: 'Продвиж.',
            children: [
                'promo_total',
                'promo_unit',
                'promo_percent',
            ]
        },
        {
            name: 'sales_promo',
            label: 'Продвиж.',
            children: [
                'sales_promo_total',
                'sales_promo_unit',
                'sales_promo_percent',
            ]
        },
        {
            name: 'advertising',
            label: 'Реклама',
            children: [
                'promo_summa',
                'promo_summa_balance'
            ]
        },
        {
            name: 'sales_advertising',
            label: 'Реклама',
            children: [
                'sales_promo_summa',
                'sales_promo_summa_balance'
            ]
        },
        {
            name: 'dues',
            label: 'Налог',
            children: [
                'dues'
            ]
        },
        {
            name: 'cost_price',
            label: 'Себес',
            children: [
                'cost_price',
                'cost_price_unit',
                'cost_price_percent',
            ]
        },
        {
            name: 'sales_cost_price',
            label: 'Себес',
            children: [
                'sales_cost_price',
                'sales_cost_price_unit',
                'sales_cost_price_percent',
            ]
        },
        {
            name: 'val_value',
            label: 'Вал. прибыль',
            children: [
                'val_value_percent',
                'summa_val_value',
                'unit_val_value',
            ]
        },
        {
            name: 'speed_sales',
            label: 'Ср. скорость продаж в день',
            children: [
                'speed_sales',
            ]
        },
        {
            name: 'surplus',
            label: 'Остаток',
            children: [
                'surplus',
            ]
        },
        {
            name: 'inwaytoclient',
            label: 'К клиенту',
            children: [
                'inwaytoclient',
            ]
        },
        {
            name: 'inwayfromclient',
            label: 'От клиента',
            children: [
                'inwayfromclient',
            ]
        },
        {
            name: 'forecast',
            label: 'Подсорт',
            children: [
                'forecast',
            ]
        },
        {
            name: 'sub_sort',
            label: 'Прогноз',
            children: [
                'sub_sort',
            ]
        },
        {
            name: 'enough_for',
            label: 'Хвати на',
            children: [
                'enough_for',
            ]
        },
    ],
    relativityGroup: {
        expenses_mp_sum: [
            'delivery',
            'delivery_unit',
            'penalty',
            'additional_payment',
            'storage_cost',
            'storage_fee_item',
            'cost_acceptance',
            'deduction'
        ],
        pay_to_finaccount: [
            'quantity',
            'summa',
            'transfer_amount',
        ],
        promo_total: [
            'promo_summa',
            'promo_summa_balance',
            /*'drr'*/
        ],
        sales_promo_total: [
            'sales_promo_summa',
            'sales_promo_summa_balance',
            /*'sales_drr'*/
        ],
    },

    ADD_STYLE_TO_SUBFIELD(unitField) {
        for (const fieldName in this.relativityGroup) {
            let childFields = this.relativityGroup[fieldName];
            for (const childFieldName of childFields) {
                if (unitField.key === childFieldName) {
                    if (!unitField.thClass) unitField.thClass = ['sub-table-header'];
                    else if (unitField.thClass.indexOf('sub-table-header') === -1) unitField.thClass.push('sub-table-header');
                    unitField.visibility = false;
                    unitField.parentVisibility = fieldName;
                    return
                } else if (unitField.key === fieldName) {
                    unitField.group = false;
                }
            }
        }
    },

    ADD_STYLE_CELL(unitField) {
        if (this.withColumnValue[unitField.key]) unitField.columnWidth = this.withColumnValue[unitField.key] + 'px'
        else unitField.columnWidth = '80px'
    },
    ADD_CLASS_TH(unitField) {
        if (!unitField.thClass) unitField.thClass = [`sub-header-${unitField.key}`];
        else unitField.thClass.push(`sub-header-${unitField.key}`);
    },
    ADD_MAIN_HEADER(unitField) {
        for (const fieldName in this.mainHeaderRelativity) {
            let headerItem = this.mainHeaderRelativity[fieldName];
            for (const fieldName of headerItem.children) {
                if (unitField.key === fieldName) {
                    unitField.mainHeader = headerItem.label
                    unitField.mainHeaderName = headerItem.name
                    return
                }
            }
        }
        unitField.mainHeader = false
    },
    updateUnitFields() {
        for (const unitField of this.listFields) {
            unitField.showBySet = true
            this.ADD_STYLE_TO_SUBFIELD(unitField)
            this.ADD_MAIN_HEADER(unitField)
            this.ADD_STYLE_CELL(unitField)
            this.ADD_CLASS_TH(unitField)
        }
    }
}

t.updateUnitFields()

export default {
    namespaced: true,
    state: {
        classCell: t.classCell,
        sets: t.sets,
        currentSet: 'orders',
        classTH: t.classTH,
        classMainTH: t.classMainTH,
        listFields: t.listFields,
        relativityGroup: t.relativityGroup,
        mainHeaderRelativity: t.mainHeaderRelativity,
        warehouses: [],
        stock: [],
    },
    getters: {
        getFieldsByGroup(state) {
            return state.listFields.filter(field => (!field.hasOwnProperty('visibility') || field.visibility === true) && state.sets[state.currentSet].set.indexOf(field.key) !== -1)
        },

        getMainHeaders(state, getters) {
            let mainHeaders = []
            let mainHeadersReg = {}
            for (const unitField of getters.getFieldsByGroup) {
                if (!mainHeadersReg[unitField.mainHeaderName || unitField.key]) {
                    mainHeadersReg[unitField.mainHeaderName || unitField.key] = {
                        [unitField.mainHeaderName || unitField.key]: 1
                    };
                    let mainHeader = {
                        hr: unitField.mainHeader || '',
                        val: 1,
                        mainHeaderName: unitField.mainHeaderName,
                        visibility: unitField.visibility,
                        label: unitField.label,
                        key: unitField.key,
                        parentVisibility: unitField.parentVisibility,
                    }
                    if (unitField.hasOwnProperty('group')) mainHeader.group = unitField.group;
                    mainHeaders.push(mainHeader);
                } else {
                    let hr = mainHeaders.find(item => item.mainHeaderName === unitField.mainHeaderName);
                    hr.val++
                }
            }
            return mainHeaders
        }
    },

    mutations: {
        SET_WAREHOUSES_BY_REGION(state, warehouses) {
            state.warehouses = warehouses
        },
        SET_STOCK(state, stock) {
            state.stock = stock
        },
    },
    actions: {
        setVisibilityFields({state}, {parentField}) {
            for (const field of state.listFields) {
                if (field.parentVisibility === parentField) field.visibility = !field.visibility;
                if (field.key === parentField) field.group = !field.group;
            }
        },
        changesets({state}, {setName, val}) {
            state.currentSet = setName
        },
        async getWarehouses({commit, dispatch}, data) {
            try {
                let warehouses = await axios.post('/statistics/warehouses/get_list', data);
                if (warehouses.data) {
                    commit("SET_WAREHOUSES_BY_REGION", warehouses.data);
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },
        async getStock({commit, dispatch}, data) {
            try {
                let stock = await axios.post('/statistics/warehouses/get_stock', data);
                if (stock.data) {
                    commit("SET_STOCK", stock.data);
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },
    },
}
