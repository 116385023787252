import axios from "axios";

export default {
    namespaced: true,
    state: {
        capitalization: {},
        inventoryBalance: {},
        prepaymentForGoods: {},
        totalSummaCredit: {},
        totalSummaExecutionCredit: {},
        totalSummaBusinessOwner: {},
        totalSummaExecutionBusinessOwner: {},
        totalSummaInvestments: {},
        totalSummaExecutionInvestments: {},



    },
    getters: {},
    mutations: {
        SET_INVESTMENT(state, data) {
            state.totalSummaCredit = data.totalSummaCredit;
            state.totalSummaExecutionCredit = data.totalSummaExecutionCredit;
            state.totalSummaBusinessOwner = data.totalSummaBusinessOwner;
            state.totalSummaExecutionBusinessOwner = data.totalSummaExecutionBusinessOwner;
            state.totalSummaInvestments = data.totalSummaInvestments;
            state.totalSummaExecutionInvestments = data.totalSummaExecutionInvestments;
        },
        SET_CAPITALIZATION(state, data) {
            state.capitalization = data.capitalization.totalIncome;
            state.inventoryBalance = data.inventoryBalance.summaTotolIventoryBalance;
            state.prepaymentForGoods = data.prepaymentForGoods.total;
        },
    },
    actions: {
        async getInvestment({commit, dispatch}) {
            try {
                let res = await axios.get('/statistics/econ/get_investment');
                if (res.data) {
                    commit('SET_INVESTMENT', res.data);
                }
            } catch (e) {
                console.log(e);
            }
        },
        async getCapitalization({commit}) {
            try {
                let res = await axios.post('/statistics/econ/get_capitalization');
                if (res.data) {
                    commit('SET_CAPITALIZATION', res.data);
                    return true;
                }
            } catch (e) {
                console.log(e);
            }
            return false
        },
    },
}
