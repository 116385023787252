import axios from "axios";

export default {
    namespaced: true,

    state: {
        totalProducts: 0,
        products: [],
        brands: [],
        objects: [],
        loadProductsProcess: false,
        page: 1,
        selectedProductsNmID: [],
        pageSize: 10
    },

    getters: {
        getProducts(state) {
            return state.products
        },
    },

    mutations: {
        SET_PRODUCTS(store, products) {
            store.products = products
        },
        SET_BRANDS(store, brands) {
            store.brands = brands
        },
        SET_OBJECTS(store, objects) {
            store.objects = objects
        },
        SET_PRODUCT_TOTAL(store, total) {
            store.totalProducts = total
        },
        SELECT_ALL_PRODUCTS(store) {
            store.products.forEach(prod => {
                if (!store.selectedProductsNmID.includes(prod.nmID)) store.selectedProductsNmID.push(prod.nmID)
            });
        },
        REMOVE_ALL_PRODUCTS(store) {
            store.selectedProductsNmID = [];
        },
        SELECT_PRODUCT(store, nmID) {
            store.selectedProductsNmID.push(nmID);
        },
        REMOVE_PRODUCT(store, nmID) {
            let i = store.selectedProductsNmID.findIndex(item => item === nmID);
            if (typeof i === "number") store.selectedProductsNmID.splice(i, 1);
        },
    },

    actions: {
        async loadNomenclatures({state, commit, dispatch}, data = {}) {
            try {
                const {page, pageSize, subjectName, brand, find} = data;
                let res = await axios.get('/nomenclatures/get_noms', {
                    params: {
                        page,
                        pageSize,
                        subjectName,
                        brand,
                        find
                    }
                })
                if (res.data.products.noms) {
                    commit('SET_PRODUCTS', res.data.products.noms);
                    commit('SET_PRODUCT_TOTAL', res.data.products.total);
                    commit('SET_BRANDS', res.data.products.listBrands);
                    commit('SET_OBJECTS', res.data.products.listObjects);
                    state.loadProductsProcess = true
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            state.loadProductsProcess = true
            return false
        },
        selectAllProducts({state, commit, dispatch}) {
            commit('SELECT_ALL_PRODUCTS');
        },
        removeAllProducts({state, commit, dispatch}) {
            commit('REMOVE_ALL_PRODUCTS');
        },
        selectProduct({state, commit, dispatch}, nmID) {
            commit('SELECT_PRODUCT', nmID);
        },

        clearsSelectNmId({state, commit, dispatch}) {
            state.selectedProductsNmID = [];
        },




        removeProduct({state, commit, dispatch}, nmID) {
            commit('REMOVE_PRODUCT', nmID);
        }
    },
}
