export default {
    namespaced: true,
    state: {
        listChecksDefects: [
            {
                "id": 1,
                "name": "defect-check",
                "label": "Проверить на брак",
                "description": "Проверить товары на дефекты, например: проверка на комплектность, на соответствие, на целостность упаковки и тому подобное...",
                "price": 10.0,
                "options": [
                    {
                        "name": "completeness-check",
                        "label": "Проверка на комплектность",
                        "description": "Проверка на наличие всех компонентов",
                        "price": 5.0
                    },
                    {
                        "name": "quality-check",
                        "label": "Проверка на качество",
                        "description": "Проверка на наличие дефектов и отклонений",
                        "price": 8.0
                    }
                ]
            },
            {
                "id": 2,
                "name": "integrity-check",
                "label": "Проверить на целостность",
                "description": "Проверить товары на сохранность упаковки и целостность",
                "price": 7.0,
                "descriptionDetails": "Проверить товары на дефекты, такие как проверка на комплектность, соответствие и целостность упаковки",
            },
            {
                "id": 3,
                "name": "compliance-check",
                "label": "Проверить на соответствие",
                "description": "Проверить товары на соответствие заданным параметрам",
                "price": 6.0
            },
            {
                "id": 4,
                "name": "functionality-check",
                "label": "Проверить функциональность",
                "description": "Проверить работоспособность товаров",
                "price": 9.0
            },
            {
                "id": 5,
                "name": "other-check",
                "label": "Прочие проверки",
                "description": "Дополнительные проверки товаров",
                "price": 4.0
            }
        ],
        services: [
            {
                id: 11,
                priority: 5,
                key: "unloading",
                label: 'разгрузка',
                description: "",
                price: 0,
                coefficient: [
                    {
                        value: 1,
                        amount: 999
                    },
                    {
                        value: 0.9,
                        amount: 2499
                    },
                    {
                        value: 0.8,
                        amount: 4999
                    },
                ]
            },
            {
                id: 12,
                priority: 6,
                key: "acceptance",
                label: 'приемка товара',
                description: "",
                price: 0,
                coefficient: [
                    {
                        value: 1,
                        amount: 999
                    },
                    {
                        value: 0.9,
                        amount: 2499
                    },
                    {
                        value: 0.8,
                        amount: 4999
                    },
                ]
            },
            {
                id: 15,
                priority: 9,
                key: "deliveryToMP_pack",
                label: 'Доставка на МП',
                description: "",
                price: 10,
                variants: [
                    {
                        label: "WB",
                        price: [350, 2000]
                    },
                    {
                        label: "Ozon",
                        price: [350, 2500]
                    },
                ],
                coefficient: [
                    {
                        value: 1,
                        amount: 999
                    },
                    {
                        value: 0.9,
                        amount: 2499
                    },
                    {
                        value: 0.8,
                        amount: 4999
                    },
                ],
            },
            {
                id: 0,
                priority: 8,
                key: "first_pack",
                label: '1 слой упаковки',
                description: "",
                price: 10,
                variants: [
                    {
                        label: "ZIP - пакет",
                        price: {
                            "8x12": 8,
                            "10x10": 8,
                            "10x15": 8,
                            "8x18": 8,
                            "10x17": 10,
                            "10x18": 12,
                            "15x20": 15,
                            "18x18": 17,
                            "18x25": 20,
                            "20x25": 22,
                            "20x30": 25,
                            "25x35": 27,
                            "30x40": 30,
                            "35x45": 35,
                            "35x50": 40,
                        }
                    },
                    {
                        label: "БОПП пакет",
                        price: {
                            "8x12": 8,
                            "10x10": 8,
                            "10x15": 8,
                            "8x18": 8,
                            "10x17": 8,
                            "10x18": 10,
                            "15x20": 13,
                            "18x18": 15,
                            "18x25": 18,
                            "20x25": 20,
                            "20x30": 23,
                            "25x35": 25,
                            "30x40": 28,
                            "35x45": 33,
                            "35x50": 38,
                        }
                    },
                    {
                        label: "Zip lock c бегунком",
                        price: {
                            "8x12": 10,
                            "10x10": 10,
                            "10x15": 10,
                            "8x18": 10,
                            "10x17": 13,
                            "10x18": 15,
                            "15x20": 18,
                            "18x18": 20,
                            "18x25": 23,
                            "20x25": 25,
                            "20x30": 28,
                            "25x35": 30,
                            "30x40": 33,
                            "35x45": 38,
                            "35x50": 43,
                        }
                    },
                    {
                        label: "Пузырчатый конверт с клеевым клапаном",
                        price: {
                            "10x15": 12,
                            "10x24": 14,
                            "13x22": 14,
                            "15x15": 14,
                            "15x17": 14,
                            "20x16": 18,
                            "14x24": 18,
                            "20x23": 18,
                            "15x30": 18,
                            "30x20": 24,
                            "30x30": 26
                        }
                    },
                    {
                        label: "Запаять товар в рукав",
                        price: 0.6
                    },
                    {
                        label: "Воздушно-пузырьковая пленка",
                        price: 0.6
                    },
                ],
                coefficient: [
                    {
                        value: 1,
                        amount: 999
                    },
                    {
                        value: 0.9,
                        amount: 2499
                    },
                    {
                        value: 0.8,
                        amount: 4999
                    },
                ],

            },
            {
                id: 1,
                priority: 9,
                key: "second_pack",
                label: '2 слой упаковки',
                description: "",
                price: 10,
                variants: [
                    {
                        label: "ZIP - пакет",
                        price: {
                            "8x12": 8,
                            "10x10": 8,
                            "10x15": 8,
                            "8x18": 8,
                            "10x17": 10,
                            "10x18": 12,
                            "15x20": 15,
                            "18x18": 17,
                            "18x25": 20,
                            "20x25": 22,
                            "20x30": 25,
                            "25x35": 27,
                            "30x40": 30,
                            "35x45": 35,
                            "35x50": 40,
                        }
                    },
                    {
                        label: "БОПП пакет",
                        price: {
                            "8x12": 8,
                            "10x10": 8,
                            "10x15": 8,
                            "8x18": 8,
                            "10x17": 8,
                            "10x18": 10,
                            "15x20": 13,
                            "18x18": 15,
                            "18x25": 18,
                            "20x25": 20,
                            "20x30": 23,
                            "25x35": 25,
                            "30x40": 28,
                            "35x45": 33,
                            "35x50": 38,
                        }
                    },
                    {
                        label: "Zip lock c бегунком",
                        price: {
                            "8x12": 10,
                            "10x10": 10,
                            "10x15": 10,
                            "8x18": 10,
                            "10x17": 13,
                            "10x18": 15,
                            "15x20": 18,
                            "18x18": 20,
                            "18x25": 23,
                            "20x25": 25,
                            "20x30": 28,
                            "25x35": 30,
                            "30x40": 33,
                            "35x45": 38,
                            "35x50": 43,
                        }
                    },
                    {
                        label: "Пузырчатый конверт с клеевым клапаном",
                        price: {
                            "10x15": 12,
                            "10x24": 14,
                            "13x22": 14,
                            "15x15": 14,
                            "15x17": 14,
                            "20x16": 18,
                            "14x24": 18,
                            "20x23": 18,
                            "15x30": 18,
                            "30x20": 24,
                            "30x30": 26
                        }
                    },
                    {
                        label: "Запаять товар в рукав",
                        price: 0.6
                    },
                    {
                        label: "Воздушно-пузырьковая пленка",
                        price: 0.6
                    },
                ],
                coefficient: [
                    {
                        value: 1,
                        amount: 999
                    },
                    {
                        value: 0.9,
                        amount: 2499
                    },
                    {
                        value: 0.8,
                        amount: 4999
                    },
                ],
            },
            {
                id: 2,
                priority: 10,
                key: "check_defect",
                label: 'проверить на брак',
                description: "",
                price: 8,
                coefficient: [
                    {
                        value: 1,
                        amount: 999
                    },
                    {
                        value: 0.9,
                        amount: 2499
                    },
                    {
                        value: 0.8,
                        amount: 4999
                    },
                ]
            },
            {
                id: 9,
                priority: 17,
                key: "linerAttachment",
                label: 'вложить вкладыш',
                description: "",
                price: 3,
                coefficient: [
                    {
                        value: 1,
                        amount: 999
                    },
                    {
                        value: 0.9,
                        amount: 2499
                    },
                    {
                        value: 0.8,
                        amount: 4999
                    },
                ]
            },
            {
                id: 17,
                priority: 22,
                key: "sorting",
                label: 'сортировка',
                description: "",
                price: 2,
                coefficient: [
                    {
                        value: 1,
                        amount: 999
                    },
                    {
                        value: 0.9,
                        amount: 2499
                    },
                    {
                        value: 0.8,
                        amount: 4999
                    },
                ]
            },
            {
                id: 3,
                priority: 11,
                key: "make_set",
                label: 'сделать комплект',
                description: "",
                price: 30,
                input: true,
                coefficient: [
                    {
                        value: 1,
                        amount: 999
                    },
                    {
                        value: 0.9,
                        amount: 2499
                    },
                    {
                        value: 0.8,
                        amount: 4999
                    },
                ]
            },
            {
                id: 4,
                priority: 12,
                key: "mark_prod",
                label: 'маркировка товара',
                description: "",
                price: 6,
                coefficient: [
                    {
                        value: 1,
                        amount: 999
                    },
                    {
                        value: 0.9,
                        amount: 2499
                    },
                    {
                        value: 0.8,
                        amount: 4999
                    },
                ]
            },
            {
                id: 5,
                priority: 13,
                key: "mark_package",
                label: 'маркировка упаковки',
                description: "",
                price: 6,
                coefficient: [
                    {
                        value: 1,
                        amount: 999
                    },
                    {
                        value: 0.9,
                        amount: 2499
                    },
                    {
                        value: 0.8,
                        amount: 4999
                    },
                ]
            },
            {
                id: 6,
                key: "removeTag",
                priority: 14,
                label: 'убрать бирку производителя',
                description: "",
                price: 4,
                coefficient: [
                    {
                        value: 1,
                        amount: 999
                    },
                    {
                        value: 0.9,
                        amount: 2499
                    },
                    {
                        value: 0.8,
                        amount: 4999
                    },
                ]
            },
            {
                id: 7,
                priority: 15,
                key: "addTag",
                label: 'повесить бирку',
                description: "",
                price: 9,
                coefficient: [
                    {
                        value: 1,
                        amount: 999
                    },
                    {
                        value: 0.9,
                        amount: 2499
                    },
                    {
                        value: 0.8,
                        amount: 4999
                    },
                ]
            },
            {
                id: 8,
                priority: 16,
                key: "sealTheBox",
                label: 'заклеить коробку',
                description: "",
                price: 3,
                coefficient: [
                    {
                        value: 1,
                        amount: 999
                    },
                    {
                        value: 0.9,
                        amount: 2499
                    },
                    {
                        value: 0.8,
                        amount: 4999
                    },
                ]
            },
            {
                id: 10,
                priority: 18,
                key: "steaming",
                label: 'отпаривание',
                description: "",
                price: 25,
                coefficient: [
                    {
                        value: 1,
                        amount: 999
                    },
                    {
                        value: 0.9,
                        amount: 2499
                    },
                    {
                        value: 0.8,
                        amount: 4999
                    },
                ]
            },
        ],
        listServices: [
            {
                "id": 1,
                "priority": 5,
                "name": "firstPack",
                "key": "firstPack",
                "label": "Первый слой упаковки",
                "description": "",
                "price": 0,
                "value": 0,
                "options": [
                    {
                        "name": "zipPack",
                        img: '/images/products/zip.png',
                        "label": "ZIP - пакет",
                        "description": "",
                        "price": {
                            "8x12": 8,
                            "10x10": 8,
                            "10x15": 8,
                            "8x18": 8,
                            "10x17": 10,
                            "10x18": 12,
                            "15x20": 15,
                            "18x18": 17,
                            "18x25": 20,
                            "20x25": 22,
                            "20x30": 25,
                            "25x35": 27,
                            "30x40": 30,
                            "35x45": 35,
                            "35x50": 40,
                        }
                    },
                    {
                        "name": "boppPack",
                        "label": "БОПП пакет",
                        "img": '/images/products/bopp.png',
                        "description": "",
                        "price": {
                            "8x12": 8,
                            "10x10": 8,
                            "10x15": 8,
                            "8x18": 8,
                            "10x17": 8,
                            "10x18": 10,
                            "15x20": 13,
                            "18x18": 15,
                            "18x25": 18,
                            "20x25": 20,
                            "20x30": 23,
                            "25x35": 25,
                            "30x40": 28,
                            "35x45": 33,
                            "35x50": 38,
                        },
                    },
                    {
                        "name": "zipLock",
                        "label": "Zip lock c бегунком",
                        "description": "",
                        "price": {
                            "8x12": 10,
                            "10x10": 10,
                            "10x15": 10,
                            "8x18": 10,
                            "10x17": 13,
                            "10x18": 15,
                            "15x20": 18,
                            "18x18": 20,
                            "18x25": 23,
                            "20x25": 25,
                            "20x30": 28,
                            "25x35": 30,
                            "30x40": 33,
                            "35x45": 38,
                            "35x50": 43,
                        },
                    },
                    {
                        "name": "bubblyPack",
                        "label": "Пузырчатый конверт с клеевым клапаном",
                        "description": "",
                        "price": {
                            "10x15": 12,
                            "10x24": 14,
                            "13x22": 14,
                            "15x15": 14,
                            "15x17": 14,
                            "20x16": 18,
                            "14x24": 18,
                            "20x23": 18,
                            "15x30": 18,
                            "30x20": 24,
                            "30x30": 26
                        },
                    },
                    {
                        "name": "sleeve",
                        "img": '/images/products/hand.png',
                        "label": "Запаять товар в рукав",
                        "description": "",
                        "price": 0.6,
                    },
                    {
                        "name": "airBubble",
                        "img": '/images/products/bubble.png',
                        "label": "Воздушно-пузырьковая пленка",
                        "description": "",
                        "price": 0.6,
                    },
                ]
            },
            {
                "id": 3,
                "priority": 6,
                "name": "secondPack",
                "key": "secondPack",
                "label": "второй слой упаковки",
                "description": "",
                "price": 0,
                "value": 0,
                "options": [
                    {
                        "name": "zipPack",
                        img: '/images/products/zip.png',
                        "label": "ZIP - пакет",
                        "description": "",
                        "price": {
                            "8x12": 8,
                            "10x10": 8,
                            "10x15": 8,
                            "8x18": 8,
                            "10x17": 10,
                            "10x18": 12,
                            "15x20": 15,
                            "18x18": 17,
                            "18x25": 20,
                            "20x25": 22,
                            "20x30": 25,
                            "25x35": 27,
                            "30x40": 30,
                            "35x45": 35,
                            "35x50": 40,
                        }
                    },
                    {
                        "name": "boppPack",
                        "label": "БОПП пакет",
                        "img": '/images/products/bopp.png',
                        "description": "",
                        "price": {
                            "8x12": 8,
                            "10x10": 8,
                            "10x15": 8,
                            "8x18": 8,
                            "10x17": 8,
                            "10x18": 10,
                            "15x20": 13,
                            "18x18": 15,
                            "18x25": 18,
                            "20x25": 20,
                            "20x30": 23,
                            "25x35": 25,
                            "30x40": 28,
                            "35x45": 33,
                            "35x50": 38,
                        },
                    },
                    {
                        "name": "zipLock",
                        "label": "Zip lock c бегунком",
                        "description": "",
                        "price": {
                            "8x12": 10,
                            "10x10": 10,
                            "10x15": 10,
                            "8x18": 10,
                            "10x17": 13,
                            "10x18": 15,
                            "15x20": 18,
                            "18x18": 20,
                            "18x25": 23,
                            "20x25": 25,
                            "20x30": 28,
                            "25x35": 30,
                            "30x40": 33,
                            "35x45": 38,
                            "35x50": 43,
                        },
                    },
                    {
                        "name": "bubblyPack",
                        "label": "Пузырчатый конверт с клеевым клапаном",
                        "description": "",
                        "price": {
                            "10x15": 12,
                            "10x24": 14,
                            "13x22": 14,
                            "15x15": 14,
                            "15x17": 14,
                            "20x16": 18,
                            "14x24": 18,
                            "20x23": 18,
                            "15x30": 18,
                            "30x20": 24,
                            "30x30": 26
                        },
                    },
                    {
                        "name": "sleeve",
                        "img": '/images/products/hand.png',
                        "label": "Запаять товар в рукав",
                        "description": "",
                        "price": 0.6,
                    },
                    {
                        "name": "fromDeveloper",
                        "label": "упаковка о производителя",
                        "description": "",
                        "price": 0,
                    },
                    {
                        "name": "without",
                        "label": "без упаковки",
                        "description": "",
                        "price": 0,
                    },
                    {
                        "name": "airBubble",
                        "img": '/images/products/bubble.png',
                        "label": "Воздушно-пузырьковая пленка",
                        "description": "",
                        "price": 0.6,
                    },
                ]
            },
            {
                "id": 2,
                "priority": 7,
                "name": "equipment",
                "key": "equipment",
                "label": "Сделать комплект",
                "description": "Сделать комплект",
                "price": 6,
                "value": 0,
                "inputData": [
                    {
                        "key": 'amountIncluded',
                        "value": '',
                        "label": 'Количество в комплекте(шт)',
                        "description": 'Количество в комплекте',
                    }
                ],
            },
            {
                "id": 4,
                "priority": 8,
                "name": "checkDefect",
                "key": "checkDefect",
                "label": "проверить на брак",
                "description": "проверить на брак",
                "price": 8,
                "value": 0,
            },
            {
                "id": 5,
                "priority": 9,
                "name": "linerAttachment",
                "key": "linerAttachment",
                "label": "вложить вкладыш",
                "description": "вложить вкладыш",
                "price": 3,
                "value": 0,
            },
            {
                "id": 6,
                "priority": 10,
                "name": "sorting",
                "key": "sorting",
                "label": "сортировка",
                "description": "сортировка",
                "price": 2,
                "value": 0,
            },
            {
                "id": 7,
                "priority": 11,
                "name": "markProd",
                "key": "markProd",
                "label": "маркировка товара",
                "description": "маркировка товара",
                "price": 6,
                "value": 0,
            },
            {
                "id": 8,
                "priority": 12,
                "name": "markPackage",
                "key": "markPackage",
                "label": "маркировка упаковки",
                "description": "маркировка упаковки",
                "price": 6,
                "value": 0,
            },
            {
                "id": 9,
                "priority": 13,
                "name": "removeTag",
                "key": "removeTag",
                "label": "убрать бирку производителя",
                "description": "убрать бирку производителя",
                "price": 4,
                "value": 0,
            },
            {
                "id": 10,
                "priority": 14,
                "name": "addTag",
                "key": "addTag",
                "label": "повесить бирку",
                "description": "повесить бирку",
                "price": 9,
                "value": 0,
            },
            {
                "id": 11,
                "priority": 15,
                "name": "sealTheBox",
                "key": "sealTheBox",
                "label": "заклеить коробку",
                "description": "заклеить коробку",
                "price": 3,
                "value": 0,
            },
            {
                "id": 12,
                "priority": 16,
                "name": "steaming",
                "key": "steaming",
                "label": "отпаривание",
                "description": "отпаривание",
                "price": 25,
                "value": 0,
            },
        ],
        tableFields: [
            {
                key: 'name',
                label: 'продукт',
                priority: 1
            },
            {
                key: 'number',
                label: 'количество',
                priority: 2
            },
            {
                key: 'costOWork',
                label: 'стоимость работ',
                priority: 3
            },
            {
                key: 'newServices',
                label: 'добавить услугу',
                priority: 100
            },
        ],
    },
    getters: {},
    mutations: {
        DEL_SERVICES_ITEM(state, id) {
            for (let i = 0; i < state.tableFields.length; i++) {
                const tf = state.tableFields[i];
                if (tf.id === id) {
                    state.tableFields.splice(i, 1);
                    state.tableFields = state.tableFields.sort((tf, tf1) => {
                        if (tf.priority) return tf.priority - tf1.priority;
                    });
                    return;
                }
            }
        },
        ADD_SERVICES_ITEM(state, id) {
            let pos = state.tableFields.length - 1
            state.tableFields.splice(pos, 0, state.listServices.find(serv => serv.id === id));
            state.tableFields = state.tableFields.sort((tf, tf1) => {
                if (tf.priority) return tf.priority - tf1.priority;
            });
        },
        ADD_NEW_SERVICES_ITEM(state, newCheck) {
            if (state.listChecksDefects.every(check => check.id !== newCheck.name)) state.listChecksDefects.unshift(newCheck);
        },
    },
    actions: {
        addService({commit, dispatch}, servId) {
            commit('ADD_SERVICES_ITEM', servId);
        },
        async addCustomCheck({commit, dispatch}, {name, description}) {
            let newCheck = {id: name, name, description, label: name, price: 'расценка'}
            commit('ADD_NEW_SERVICES_ITEM', newCheck);
            return newCheck
        },
        delService({commit, dispatch}, servId) {
            commit('DEL_SERVICES_ITEM', servId);
        },
    },
}
