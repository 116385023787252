import axios from "axios";

export default {
    namespaced: true,
    state: {
        authState: null,
        listLegalStatus: [],
        countries: [],
        userInfo: {},
        duesType: null,
        duesValue: null,
        duesList: [],
    },
    getters: {},
    mutations: {
        SET_AUTH(state, authState) {
            state.authState = authState
        },
        SET_DUES(state, dues) {
            if (dues.length) {
                dues = dues[0];
                state.duesValue = dues.value
                state.duesType = dues.dues_type
            }
        },
        SET_DUES_LIST(state, duesList) {
            state.duesList = duesList
        },
        SET_USER_INFO(state, data) {
            state.userInfo = data;
        },
        SET_COUNTRIES(state, data) {
            state.countries = data
        },
        SET_USER_STATUSES_LIST(state, data) {
            state.listLegalStatus = data;
        },
    },

    actions: {
        async checkAuth({commit, dispatch}, params) {
            try {
                let res = await axios.get('/user/is_user');
                if (res.status === 200) commit('SET_AUTH', true);
            } catch (e) {
                console.log(e);
                commit('SET_AUTH', false);
            }
        },
        async loginUser({commit}, {email, password}) {
            try {
                let res = await axios.post('/user/auth_user', {email, password});
                if (res.status === 200) {
                    commit('SET_AUTH', true);
                    return true;
                }
            } catch (e) {
                console.log(e);
            }
            commit('SET_AUTH', false);
            return false
        },
        async registerUser({commit}, {email, password}) {
            try {
                let res = await axios.post('/user/register_user', {email, password});
                if (res.status === 200) {
                    return true;
                }
            } catch (e) {
                console.log(e);
            }
            return false
        },
        async checkEmail({commit}, {email}) {
            try {
                let res = await axios.post('/user/is_email_busy', {email});
                if (res.status === 200) {
                    return res.statusText;
                }
            } catch (e) {
                console.log(e);
            }
            return false
        },
        async logOut({commit}) {
            try {
                let res = await axios.get('/user/log_out');
                if (res.status === 200) {
                    commit('SET_AUTH', false);
                    return true;
                }
            } catch (e) {
                console.log(e);
            }
            return false
        },

        async loadCountries({commit, dispatch}) {
            try {
                let res = await axios.get('/userinfo/countries_list');
                if (res.status === 200) {
                    commit('SET_COUNTRIES', res.data.countries);
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },
        async loadUserInfo({commit, dispatch}, params) {
            try {
                let res = await axios.get('/userinfo/get_user_info');
                if (res.status === 200) {
                    commit('SET_USER_INFO', res.data);
                    return true;
                }
            } catch (e) {
                console.log(e);
            }
            return false
        },
        async loadUserStatuses({commit, dispatch}, params) {
            try {
                let res = await axios.get('/userinfo/get_user_statuses');
                if (res.status === 200) {
                    commit('SET_USER_STATUSES_LIST', res.data);
                    return true;
                }
            } catch (e) {
                console.log(e);
            }
            return false
        },

        async writeDues({commit, dispatch}, {dues, duesType}) {
            try {
                let res = await axios.post('/statistics/api/write_dues', {dues, duesType});
                if (res.status === 200) {
                    dispatch('getDues');
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },
        async getDuesList({commit, dispatch}) {
            try {
                let res = await axios.get('/statistics/api/get_dues_list');
                if (res.data) {
                    commit('SET_DUES_LIST', res.data);
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },

        async userConfirm({commit, dispatch}, code) {
            try {
                let res = await axios.get('/user/confirm_user', {
                    params: {
                        code
                    }
                });
                if (res.status ===  200) {
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },
        async getDues({commit, dispatch}) {
            try {
                let res = await axios.get('/statistics/api/get_dues');
                if (res.status === 200) {
                    commit('SET_DUES', res.data);
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        }
    },
}
