import axios from "axios";

const RowsBuilder = require("../../libs/TransactionTableRowsBuilder");

export default {
    namespaced: true,
    state: {
        transactions: [],
        expenseItems: [],
        finAccounts: [],
        transactionsYears: [],
        InvestmentsExpenditureDetailRows: [],
        InvestmentsReceiptsDetailRows: [],
        OperationsExpenditureDetailRows: [],
        OperationsReceiptsDetailRows: [],
        FinanceExpenditureDetailRows: [],
        FinanceReceiptsDetailRows: [],
        topRows: [],
        startMonthDetailRows: [],
        EndMonthDetailRows: [],
        OperationsBalance: [],
        InvestmentsBalance: [],
        FinanceBalance: [],
        country: [],
    },

    getters: {
        getListPurchases(state) {
            return state.purchases
        }
    },

    mutations: {
        SET_TRANSACTIONS(state, data) {
            state.transactions = data
        },

        SET_COUNTRIES(state, data) {
            state.country = data
        },

        SET_CF(state, data) {
            data = JSON.parse(JSON.stringify(data)).sort((a, b) => (a.year * 12 + a.month) - (b.year * 12 + b.month));

            let totalSumma = data.reduce((acc, item)=>acc + +item.summa, 0);
            console.log('totalSumma', totalSumma, data.length);
            const {createRows, years} = new RowsBuilder()

            let {
                InvestmentsExpenditureDetailRows,
                InvestmentsReceiptsDetailRows,
                OperationsExpenditureDetailRows,
                OperationsReceiptsDetailRows,
                FinanceExpenditureDetailRows,
                FinanceReceiptsDetailRows,
                topRows,
                startMonthDetailRows,
                EndMonthDetailRows,
                OperationsBalance,
                InvestmentsBalance,
                FinanceBalance
            } = createRows(data);
            state.InvestmentsExpenditureDetailRows = InvestmentsExpenditureDetailRows
            state.InvestmentsReceiptsDetailRows = InvestmentsReceiptsDetailRows
            state.OperationsExpenditureDetailRows = OperationsExpenditureDetailRows
            state.OperationsReceiptsDetailRows = OperationsReceiptsDetailRows
            state.FinanceExpenditureDetailRows = FinanceExpenditureDetailRows
            state.FinanceReceiptsDetailRows = FinanceReceiptsDetailRows

            state.topRows = topRows
            state.startMonthDetailRows = startMonthDetailRows
            state.EndMonthDetailRows = EndMonthDetailRows
            state.OperationsBalance = OperationsBalance
            state.InvestmentsBalance = InvestmentsBalance
            state.FinanceBalance = FinanceBalance

            state.transactionsYears = Object.keys(years)
        },

        ADD_TEMP_CREATE(state) {
            let newTr = {
                "id": null,
                "expenseItem": null,
                "typeAction": null,
                "contractor": null,
                "finAccount": null,
                "date": null,
                "dateExecution": null,
                "summa": null,
                "summaExecution": null,
                "purchaseName": null,
                "comment": null
            };
            if (!state.transactions.some(item => item.id === null)) state.transactions.unshift(newTr)
        },

        DEL_TEMP_CREATE(state, data) {
            state.transactions.some((item, i) => {
                if (item.id === null) state.transactions.splice(i, 1);
                return true
            })
        },

        SET_EXPENSE_ITEMS(state, data) {
            state.expenseItems = data
        },

        SET_FIN_ACCOUNTS(state, data) {
            state.finAccounts = data
        },
    },

    actions: {
        async getTransactions({commit, dispatch}) {
            try {
                let tran = await axios.get('/econ/get_list_econ');
                if (tran.status === 200) {
                    tran = tran.data;
                    commit('SET_TRANSACTIONS', tran);
                    commit('SET_CF', tran)
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },

        async getCatalogs({commit, dispatch}, data) {
            try {
                let lists = await axios.get('/econ/get_catalogs');
                if (lists.status === 200) {
                    lists = lists.data
                    commit('SET_EXPENSE_ITEMS', lists.expenseItems);
                    commit('SET_FIN_ACCOUNTS', lists.checkAccounts);
                    commit('SET_COUNTRIES', lists.countryList);
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },

        async create({commit, dispatch}, data) {
            try {
                let success = await axios.post('/econ/create', data);
                if (success.status === 200) {
                    dispatch('getTransactions');
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },

        async createExpenseItem({commit, dispatch}, data) {
            try {
                let success = await axios.post('/econ/create_expense_item', data);
                if (success.status === 200) {
                    dispatch('getCatalogs');
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },

        async createFinAccount({commit, dispatch}, data) {
            try {
                let success = await axios.post('/econ/create_fin_account', data);
                if (success.status === 200) {
                    dispatch('getCatalogs');
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },

        async del({dispatch}, data) {
            try {
                let success = await axios.post('/econ/del', data);
                if (success.status === 200) {
                    dispatch('getTransactions');
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },

        async addTempTransaction({commit, dispatch}, data) {
            commit('ADD_TEMP_CREATE');
        },

        async cancelCreate({commit, dispatch}, data) {
            commit('DEL_TEMP_CREATE');
        },

        async updateTransaction({commit, dispatch}, data) {
            try {
                let success = await axios.post('/econ/update_transaction', data);
                if (success.status === 200) {
                    await dispatch('getTransactions');
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },
    },
}
