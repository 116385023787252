import axios from "axios";

export default {
    namespaced: true,
    state: {
        shipments: [
            {
                id: 12,
                dimensional: true,
                products: [
                    {
                        id: 8,
                        firstImg: 'https://basket-03.wb.ru/vol358/part35868/35868169/images/big/4.jpg',
                        name: 'name',
                        article: 'article',
                        brand: 'brand',
                        amountBySize: [
                            {
                                amountUser: 5,
                                amount: 5,
                                techSize: 'S',
                                wbSize: '45',
                                skus: [
                                    "123456789123456789"
                                ],
                            },
                            {
                                amountUser: 5,
                                amount: 5,
                                techSize: 'S',
                                wbSize: '45',
                                skus: [
                                    "123456789123456789"
                                ],
                            }
                        ]
                    }
                ],
                defectCheck: {
                    "6": [
                        {serviceId: 5},
                    ],
                    "8": [
                        {serviceId: 5},
                        {serviceId: 4},
                    ],
                    "10": [
                        {serviceId: 5},
                        {serviceId: 4},
                        {serviceId: 8},
                        {serviceId: 12},
                    ],
                },
                photoIdent: "https://basket-03.wb.ru/vol358/part35868/35868169/images/big/4.jpg",
                currentStatus: "onWay",
                statuses: {
                    create: {date: new Date().toLocaleDateString(), docName: '', docType: ''},
                    handling: {date: '15.08.2023', docName: '', docType: ''},
                    onWay: {date: '', docName: '', docType: ''},
                    acceptance: {date: '', docName: '', docType: ''},
                    accepted: {date: '', docName: '', docType: ''},
                },
                date: new Date().toLocaleDateString(),
                createAt: new Date().toLocaleDateString(),
                FFDeliversTheCargo: true,
                address: "Уфа, Мира 20-12",
                amountBox: 5,
                amountPallet: 0,
                totalVolume: 1,
                comment: "Комментарий к доставке",
                country: "Россия",
            },
            {
                id: 546,
                dimensional: true,
                products: [
                    {
                        id: 8,
                        firstImg: 'https://basket-03.wb.ru/vol358/part35868/35868169/images/big/4.jpg',
                        name: 'name',
                        article: 'article',
                        brand: 'brand',
                        amountBySize: [
                            {
                                amountUser: 5,
                                amount: 5,
                                techSize: 'S',
                                wbSize: '45',
                                skus: [
                                    "123456789123456789"
                                ],
                            },
                            {
                                amountUser: 5,
                                amount: 5,
                                techSize: 'S',
                                wbSize: '45',
                                skus: [
                                    "123456789123456789"
                                ],
                            }
                        ]
                    }
                ],
                defectCheck: {
                    "6": [
                        {serviceId: 5},
                    ],
                    "8": [
                        {serviceId: 5},
                        {serviceId: 4},
                    ],
                    "10": [
                        {serviceId: 5},
                        {serviceId: 4},
                        {serviceId: 8},
                        {serviceId: 12},
                    ],
                },
                photoIdent: "https://basket-03.wb.ru/vol358/part35868/35868169/images/big/4.jpg",
                currentStatus: "accepted",
                statuses: {
                    create: {date: '14.08.2023', docName: '', docType: ''},
                    handling: {date: '15.08.2023', docName: '', docType: ''},
                    onWay: {date: '16.08.2023', docName: '', docType: ''},
                    acceptance: {date: '17.08.2023', docName: '', docType: ''},
                    accepted: {date: '18.08.2023', docName: '', docType: ''},
                },
                date: new Date().toLocaleDateString(),
                createAt: new Date().toLocaleDateString(),
                FFDeliversTheCargo: true,
                address: "Уфа, Мира 20-12",
                amountBox: 5,
                amountPallet: 0,
                totalVolume: 1,
                comment: "Комментарий к доставке",
                country: "Россия",
            },
            {
                id: 46,
                dimensional: true,
                products: [
                    {
                        id: 8,
                        firstImg: 'https://basket-03.wb.ru/vol358/part35868/35868169/images/big/4.jpg',
                        name: 'name',
                        article: 'article',
                        brand: 'brand',
                        amountBySize: [
                            {
                                amountUser: 5,
                                amount: 5,
                                techSize: 'S',
                                wbSize: '45',
                                skus: [
                                    "123456789123456789"
                                ],
                            },
                            {
                                amountUser: 5,
                                amount: 5,
                                techSize: 'S',
                                wbSize: '45',
                                skus: [
                                    "123456789123456789"
                                ],
                            }
                        ]
                    }
                ],
                defectCheck: {
                    "6": [
                        {serviceId: 5},
                    ],
                    "8": [
                        {serviceId: 5},
                        {serviceId: 4},
                    ],
                    "10": [
                        {serviceId: 5},
                        {serviceId: 4},
                        {serviceId: 8},
                        {serviceId: 12},
                    ],
                },
                photoIdent: "https://basket-03.wb.ru/vol358/part35868/35868169/images/big/4.jpg",
                currentStatus: "handling",
                statuses: {
                    create: {date: '14.08.2023', docName: '', docType: ''},
                    handling: {date: '19.08.2023', docName: '', docType: ''},
                    onWay: {date: '', docName: '', docType: ''},
                    acceptance: {date: '', docName: '', docType: ''},
                    accepted: {date: '', docName: '', docType: ''},
                },
                date: new Date().toLocaleDateString(),
                createAt: new Date().toLocaleDateString(),
                FFDeliversTheCargo: true,
                address: "Уфа, Мира 20-12",
                amountBox: 5,
                amountPallet: 0,
                totalVolume: 1,
                comment: "Комментарий к доставке",
                country: "Россия",
            },
        ],
        currentShipment: {},
    },
    getters: {

    },
    mutations: {
        CLEAR_CURRENT_SHIPMENT(state) {
            state.currentShipment = {}
        },
        SET_CURRENT_SHIPMENT(state, shipment) {
            state.currentShipment = shipment
        }
    },
    actions: {
        async loadShipments({commit, dispatch}, params) {

        },

        async sendCurrentShipment({commit,state, rootState}, currentShipment) {
            currentShipment.products = rootState.products.selectedProductsId
            await axios.post('/ff/create_shipment', currentShipment);
        },

        clearCurrentShipment({commit}) {
            commit('CLEAR_CURRENT_SHIPMENT')
        },

        setCurrentShipment({commit, getters, rootState}, currentShipment) {
            currentShipment.products = rootState.products.selectedProductsId

            commit('SET_CURRENT_SHIPMENT', currentShipment);
        }
    },
}
